import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
	OnDestroy,
} from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import {
  ModuleName,
} from '@settings/global-settings';
import {
  Ahm,
  AhmData,
  CalculationAhm,
  CalculationManual,
  CabinArea,
  Flight,
  Route,
  HoldsAndCompartmentsCalc,
  FilterParams,
  DowChangesCrew,
  UldTypes,
  Document,
  NotocDangerous,
  NotocOther,
  DeltaDowDoi,
  Workspace,
  User,
  BalanceСhart,
  Referance,
  CrewAdditionalItem,
} from '../weight-balance-data/weight-balance';
import { WeightBalanceModuleRestApiService } from '../weight-balance-data/weight-ballance-rest-api-service';
import { GlobalI18n } from '@settings/global-i18n';
import {
  ReferanceAirport,
  ReferanceAirline,
  ReferanceAircraft,
  ReferanceTail,
  ReferanceMovement,
} from '../referance-module-data/referance';
import { HttpResponse } from '@angular/common/http';
import {
  USER_ACTIONS,
  DOC_TYPES,
  TLG_TYPES,
  CREW_ADDITIONAL,
  CHANNELS,
} from '@shared/const';
import {
  drawGravity,
  createCanvas,
  WeightLimit,
} from '@shared/functions/gravity';
import { typeOfCargo } from '@shared/functions/reference';
import * as bayMapFunctons from '@shared/functions/bayMap';
import { Md5 } from 'ts-md5';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { Subject, Subscription, firstValueFrom, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import moment from 'moment';
import { Clipboard } from '@angular/cdk/clipboard';
import * as functions from '../weight-balance-data/functions';
import { NgForm } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '@core/services/settings/settings.service';
import { TimeFormat } from '@shared/models/time-format';

@Component({
  selector: 'app-weight-balance',
  templateUrl: './weight-balance.component.html',
  styleUrls: ['./weight-balance.component.less'],
})
export class WeightBalanceComponent implements OnInit, OnDestroy {
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('closeButtonTelegram') closeButtonTelegram: any;
	@ViewChild('closeButtonSendAllDocument') closeButtonSendAllDocument: any;
  @ViewChild('closeFlightCard') closeFlightCard: any;
  @ViewChild('flightForm') flightForm: any;
  @ViewChild('sendMessageForm') sendMessageForm: NgForm;
  @ViewChildren('unlocatedTypeOfCargo')
  unlocatedTypeOfCargo: QueryList<NgSelectComponent>;
  @ViewChild('flightCardAirline') flightCardAirline: NgSelectComponent;
  @ViewChild('flightCardRouteError', { static: false })
  flightCardRouteError: NgbAlert;
  @ViewChild('moveDesktopDialog') moveDesktopDialog: ElementRef;

  USER_ACTIONS = USER_ACTIONS;
  DOC_TYPES = DOC_TYPES;
  TLG_TYPES = TLG_TYPES;
  CHANNELS = CHANNELS;
  CREW_ADDITIONAL = CREW_ADDITIONAL;

	subscriptionList = new Subscription();


  private md5 = new Md5();

  viewParametrs = {
    // Текущее окно
    activeWindow: 'flight-list',
    // Текущая вкладка
    activeTab: 'standard-units',
    flightCardActiveTab: 'flight-card-tab-route',
    // Отображение данных и весах и центровке в виде таблицы или графика
    chartIsShow: true,
    // Показывать мои рейсы
    showedMyFlights: false,
    activeDesktop: 0,
    mergeDesktops: false,
    activeTabFlight: 'route',
    // Период
    displayedPeriod: null,
    // Для перезагрузки ng-select
    reloadUldTypeSelect: false,
    reloadLocationsSelect: false,
    // Отображать отмененные рейсы
    cancelled: false,
    // Отображать удаленные рейсы
    deleted: false,
  };

  targetDesktop = null;
  targetUser = null;

  // Флаг загрузки
  loading = false;
	spinSendMessageDialog = false;
  // Тип загрузки
  loadingType = '';

  // Классы для добавления элементов по кнопке add
  calculationCompartments = HoldsAndCompartmentsCalc;
  NotocDangerous = NotocDangerous;
  NotocOther = NotocOther;
  CrewAdditionalItem = CrewAdditionalItem;

  selectedBays: Array<HoldsAndCompartmentsCalc> | null = null;
  // таймаут для сброса выбранных багажников, нужен чтобы при потеле фокуса они не сразу пропадали
  timeoutClearBay;

  typeOfCargo = Object.keys(typeOfCargo).map((key) => typeOfCargo[key]);

  documentEditions: Array<Document> = [];
  currentDocumentEditions = null;
  currentDocumentView = null;
  currentTelegramView = null;

  compartmentsMap = {
    filterType: '',
    proportions: null,
    widthLayout: null,
    heightLayout: null,
    // груз, который тащим мышью
    darggableCargo: null,
    // багажник, на который притащили
    targetBay: null,
  };

  documentText = '';

  // Флаг, который блокирует кнопку печати, если точки вышли
  incorrectCalculation = false;

  // Флаг - расчет по АХМ (true) или вручную (false)
  calculationByAhm = false;

  // Багажники
  locations = {};
  // Точки маршрута после домашнего
  destination = [];
  // Типы контейнеров
  uldTypes = [];
  // Типы компоновок экипажа
  crewComposition: Array<DowChangesCrew> = [];
  // calculationCrewComposition: DowChangesCrew = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});

  // Дельта
  deltaDowDoi = new DeltaDowDoi();
  enterFactDowDoiAutomatically = true;

  // Признак существующей корректной центровки для ВС
  calculationCorrect = false;

  flights: Flight[];
  flightsHash: string | Int32Array;
  flight: Flight = new Flight();
  calculation: CalculationAhm = new CalculationAhm();
  calculationManual: CalculationManual = new CalculationManual();
  ahms: Ahm[];
  ahmData: AhmData = new AhmData();
  chartData: Array<BalanceСhart> = [];
  dataWell;
  workspaces: Array<Workspace> = [];
  users: Array<User> = [];
  username: string;

  routeNetworks: Array<{ channel: number; address: string }> = [];
	routeNetworkListForMassSends: { type: string, status: boolean, isCheck: boolean, isActive: boolean, routeNetwork: { channel: number; address: string; }[] }[] = [];
	documentListSendStatus: { type: string, status: boolean, date: Date }[] = [];
  typeSendingDoc = null;
	loadProgressList = new Map<string, boolean>();
	loadProgressPercent = 0;

  references = {
    special_load_codes: [],
    aircraft_types: [] as ReferanceAircraft[],
    airlines: [] as ReferanceAirline[],
    airports: [] as ReferanceAirport[],
    seasons: [],
    movement_types: [] as ReferanceMovement[],
    load_information_codes: [],
    tails: [] as ReferanceTail[],
  };

  impCodes = {
    dangerous: [],
    other: [],
  };

  selectLoadAnimation = {
    airlines: true,
    aircraft_types: true,
    airports: true,
    tails: true,
    movement_types: true,
    dangerous: true,
    other: true,
  };

  buffer = {
    tails: [],
    airlines: [],
    aircraft_types: [],
    airports: [],
  };

  // Размер отображаемых данных в выпадающем списке
  bufferSize = {
    tails: 50,
    airlines: 50,
    aircraft_types: 50,
    airports: 50,
  };

  numberOfItemsFromEndBeforeFetchingMore = 10;

  error = {
    errorMessage: '',
    errorType: '',
    errorBay: '',
  };

  modalType = '';
  userAnswer = null;
  userAction = null;

  filterParams: FilterParams = new FilterParams();
  filterApply = false;
  showFilter = false;
  searchFlight = '';
  messages: Array<string> = [];

  // для setInterval обновления даты AHM
  ahmLastupdateInterval = [];
  loadFlightsInterval;

  trunkMap: bayMapFunctons.CompartmentsMap =
    new bayMapFunctons.CompartmentsMap();
  draggableFlight;

  autoCalculate = this.autoCalculateFunc.bind(this);

  verifiedMessage: Array<any> = []; // для валидации сообщения

  @ViewChild('gravityCentreBlock', { static: false })
  gravityCentreBlock: ElementRef;
  @ViewChild('gravityCentreBlockBig', { static: false })
  gravityCentreBlockBig: ElementRef;
  @ViewChild('print') print!: ElementRef;

  public context: CanvasRenderingContext2D;
  public contextBig: CanvasRenderingContext2D;
  userShowTime = TimeFormat.Z;
  autoSaveData = new Subject();
  flightCardRouteErrorClosed = true;
  flightCardRouteErrorMessage = '';
  calculatedAnimation = false;
  // Режим выбора рейсов из списка
  selectFlightsState = false;
  // Массив выбранных рейсов, содержит идентификаторы
  markFlightList: number[] = [];
  // Тип отображаемого линейного графика
  linearCenteringGraphicsType = '';
  linearCenteringGraphicsTypeName = 'TOW';
  // Для отлавливания 1 или 2 клика
  flightClickCount = 0;

  constructor(
    public restApi: WeightBalanceModuleRestApiService,
    private fileSaverService: FileSaverService,
    private settingsService: SettingsService,
    public globalI18n: GlobalI18n,
    private clipboard: Clipboard,
  ) {}

  get today() {
    return new Date();
  }

  async ngOnInit(): Promise<void> {
		this.generateLoadProgressList();
		this.loadReferences();
    this.loadWorkspaces();
		this.loadWindow();

		this.subscriptionList.add(
			this.settingsService.user.displayedPeriod$.subscribe(hour => {
				this.viewParametrs.displayedPeriod = hour;
			})
		);

    this.messages['current'] = this.globalI18n.getMessage(
      ModuleName.Schedule,
      'current'
    );

    this.changeLinearCenteringGraphicsType(
			this.settingsService.user.getLinearCenteringGraphicsType()
    );

		this.subscriptionList.add(
			this.settingsService.user.login$.subscribe(login => {
				this.username = login;
			})
		);

		this.subscriptionList.add(
			this.settingsService.user.settings$.subscribe(() => {
				this.userShowTime = this.settingsService.user.getTime();
			})
		)

		this.subscriptionList.add(
			this.settingsService.user.time$.subscribe(time => {
				if (time) {
					this.userShowTime = time;
				}
			})
		);

		this.subscriptionList.add(
			this.settingsService.user.timeZone$.subscribe(name => {
				if (name) {
					if (this.flightsHash) {
						this.flightsHash = null;
						this.loadFlights();
						// Если есть данные по активному рейсу, необходимо перезагрузить
						// для верного отображения времен
						if (this.flight.id) {
							this.selectFlight(this.flight.id);
						}
					}
				}
			})
		)

    // debounceTime() - время в миллисекундах перед сохранением данных, если в течении
    // этого времени приходят изменения то будет сохранено последнее состояние
    // distinctUntilChanged() - сравнение полученого объекта на изменения, если
    // данные прежние, сохранение не проихсодит
    this.autoSaveData
      .pipe(
        debounceTime(750),
        distinctUntilChanged(),
        switchMap(() => {
          if (this.viewParametrs.activeWindow === 'calculation') {
            if (this.userAction === USER_ACTIONS.ADD_CALCULATION) {
              return this.addCalculateWithoutCheck();
            } else {
              return this.saveCalculateWithoutCheck();
            }
          } else if (this.viewParametrs.activeWindow === 'calculation-manual') {
            if (this.userAction === USER_ACTIONS.ADD_CALCULATION_MANUAL) {
              return this.addCalculateManualWithoutCheck();
            } else {
              return this.saveCalculateManualWithoutCheck();
            }
          }
        })
      )
      .subscribe();

		this.loadFlightsInterval = setInterval(() => {
      this.loadFlights();
    }, 60000);
  }

	ngOnDestroy() {
		this.subscriptionList.unsubscribe();
	}

  get plannedPayload() {
    let weight = 0;
    this.flight.route.forEach((route) => {
      weight += route.loading.adult * this.calculation.passengers.weights.adult;
      weight +=
        route.loading.female * this.calculation.passengers.weights.female;
      weight += route.loading.child * this.calculation.passengers.weights.child;
      weight +=
        route.loading.infant * this.calculation.passengers.weights.infant;
      weight +=
        route.loading.cargo + route.loading.mail + route.loading.baggageWeight;
      if (!this.calculation.passengers.weights.handLuggageIncluded) {
        weight += route.loading.handLuggage;
      }
    });
    return weight;
  }

  positionAvailable(maxWeight, allWeight?: number) {
    let available = maxWeight;
    if (allWeight) {
      available = maxWeight - allWeight;
    }
    return available;
  }

  get plannedUnderload() {
    return this.calculation.ll - this.plannedPayload;
  }

  get underload() {
    return this.calculation.ll - this.calculation.ttl;
  }

	async changeDisplayedPeriod(displayedPeriod) {
		this.settingsService.user.setDisplayedPeriod(displayedPeriod);
    this.viewParametrs.displayedPeriod = displayedPeriod;
    this.loadFlights();
  }

  getPeriod(displayedPeriod: number) {
    const currentTime = new Date();
    return {
      start: new Date(
        new Date().setHours(currentTime.getHours() - displayedPeriod / 2)
      ),
      finish: new Date(
        new Date().setHours(currentTime.getHours() + displayedPeriod / 2)
      ),
    };
  }

  autoSave() {
    // Объект необходимо преобразовывать в JSON, так как далее происходит
    // проверка на наличии изменений по принципу оператора ===
    // в таком случае сравнивается сам объект а не его содержимое
    if (
      this.flight.assignee !== null &&
      this.flight.assignee !== this.username
    ) {
      this.chooseMeAsPerformer(this.flight.id);
    } else {
      if (this.flight.assignee === null) {
        this.choicePerformer(this.flight.id, this.username);
        this.flight.assignee = this.username;
      }
      this.autoSaveData.next(JSON.stringify(this.calculation));
    }
  }

  // Workspaces
  loadWorkspaces() {
    this.restApi.getWorkspaces().then(data => {
			const desktopMain = new Workspace();
			desktopMain.id = 0;
			desktopMain.name = 'Main';
			this.workspaces = [desktopMain];
			data.forEach((el) => {
				const workspace = new Workspace();
				Object.assign(workspace, el);
				if (this.checkWorkspaceRule(workspace.id)) {
					this.workspaces.push(workspace);
				}
			});
			this.completedLoadProgressForItem('workspaces');
		});
  }

  checkWorkspaceRule(id: number): boolean {
    for (const element of this.settingsService.getWeightBalanceWorkspaces()) {
      if (Number(element.id) === id && element.rule === 'all') {
        return true;
      }
    }
    return false;
  }

  showMyFlights() {
    this.viewParametrs.showedMyFlights = !this.viewParametrs.showedMyFlights;
    this.loadFlights();
  }

  changeDesktop(id = null) {
    this.flight = new Flight();
    this.viewParametrs.activeDesktop = id;
    this.loadFlights();
    return;
  }

  mergeDesktops() {
    this.viewParametrs.mergeDesktops = !this.viewParametrs.mergeDesktops;
    if (this.viewParametrs.mergeDesktops) {
      this.viewParametrs.activeDesktop = null;
    } else {
      this.viewParametrs.activeDesktop = 0;
    }
    this.loadFlights();
  }

  async moveDesktop(targetDesktop: number, flightId: number) {
    const xRequestId = this.settingsService.getRandomUuid();
    this.documentText = '';
    this.calculationCorrect = false;
    return this.restApi
      .moveDesktop(targetDesktop, flightId, xRequestId)
      .catch((err) => {
        this.displayError(err);
        this.loading = false;
      });
  }

  moveFlightsToDesktop(workspace: number, single?: boolean) {
    const moveFlights = async () => {
      if (this.selectFlightsState) {
        // Если активирован режим мультивыбора рейсов
        // переместить все выбранные рейсы на другой рабочий стол
        for (const item of this.flightList) {
          const index = this.markFlightList.indexOf(item.id);
          if (index !== -1) {
            await this.moveDesktop(workspace, item.id);
            this.markFlightList.splice(index, 1);
          }
        };
      } else if (!this.selectFlightsState && this.draggableFlight) {
        // Режим перетаскивания 1 рейса с помощью мышки
        await this.moveDesktop(workspace, this.draggableFlight);
        this.draggableFlight = null;
      } else if (single && this.flight.id) {
        // Перенос выделенного рейса в списке через диалоговое окно
        await this.moveDesktop(workspace, this.flight.id);
      } else {
        return Promise.resolve('Not refresh');
      }
    };
    // После завершения переноса рейсов/рейса закрыть диалог и обновить
    // список рейсов
    moveFlights().then((data) => {
      if (data !== 'Not refresh') {
        this.closebutton.nativeElement.click();
        setTimeout(() => this.loadFlights(), 600);
      }
    });
  }

  async moveOneFlightToDesktop(workspace: number) {
    if (this.flight.id) {
      this.moveDesktop(workspace, this.flight.id).then(() => {
        this.closebutton.nativeElement.click();
        this.loadFlights();
      });
    }
  }

  /** Predicate function that only allows even numbers to be dropped into a list. */
  evenPredicate(item: CdkDrag<number>) {
    return item.data % 2 === 0;
  }

  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }

  // Flights
  async loadFlights() {
    const xRequestId = this.settingsService.getRandomUuid();

    this.ahmLastupdateInterval.forEach((interval) => {
      clearInterval(interval);
    });
    let params: FilterParams = new FilterParams();
    params = this.filterApply
      ? this.filterParams
      : this.getPeriod(this.viewParametrs.displayedPeriod);
    params.assignee = this.viewParametrs.showedMyFlights ? this.username : null;
    // Скрывать по умолчанию удаленные и отмененные рейса если не установен
    // фильтр
    if (this.filterApply) {
      params.cancelled = this.filterParams.cancelled;
      params.deleted = this.filterParams.deleted;
    } else {
      params.cancelled = false;
      params.deleted = false;
    }
    await this.restApi
      .getFlights(params, this.viewParametrs.activeDesktop, xRequestId)
      .then(
        (data) => {
          this.md5.start();
          const newDataHash =
            data.length > 0
              ? this.md5.appendStr(JSON.stringify(data)).end()
              : '';
          // Проверка хеша текущих и новых данных
          if (this.flightsHash !== newDataHash) {
            this.flights = [];
            data.forEach((el) => {
              const flight = new Flight();
              Object.assign(flight, el);
              this.flights.push(flight);
            });
            // Обновление хеша сохраненных данных
            this.flightsHash = newDataHash;
            this.loading = false;
          }
        },
        (err) => {
          this.displayError(err);
        }
      );
  }

  get flightList(): Flight[] {
    if (this.flights && this.flights.length > 0) {
      return this.flights.filter((item) => {
        if (this.searchFlight !== '') {
          return (
            (item.airlineIata &&
              item.airlineIata
                .toLowerCase()
                .indexOf(this.searchFlight.toLowerCase()) !== -1) ||
            (item.flightNumber &&
              item.flightNumber
                .toLowerCase()
                .indexOf(this.searchFlight.toLowerCase()) !== -1)
          );
        } else {
          return item;
        }
      });
    } else {
      return [];
    }
  }

	// TODO если убрать ? то в консоле видно что при переходе к разделу настроек
	// данная функция вызывается все время и насыпает ошибок, разобраться почему
  selectFirstTab() {
    document
      .querySelectorAll('.nav-tabs li a')
      ?.forEach((el) => el.classList.remove('active'));
    document
      .querySelector('.nav-tabs li:first-child a')
      .classList.add('active');
    document
      .querySelectorAll('.tab-content > div')
      ?.forEach((el) => el.classList.remove('show', 'active'));
    document
      .querySelector('.tab-content > div:first-child')
      .classList.add('show', 'active');
  }

  createFlight() {
    this.flight = new Flight(this.viewParametrs.activeDesktop);
    this.flightForm.form.markAsUntouched();
    this.flightForm.reset();
    this.calculationByAhm = false;
    this.selectFirstTab();

    // Создание дат по умолчанию должно учитывать текущуий формат отображаемого
    // времени пользователя
    let startDayTime = null;
    if (this.userShowTime === TimeFormat.Z) {
      startDayTime = moment().utcOffset('+0000').startOf('day');
    } else {
      startDayTime = moment()
        .utcOffset(this.settingsService.timeZone.getUserTimeZoneOffset())
        .startOf('day');
    }
    this.flight.route[0].dtDepartureScheduled = new Date(startDayTime.format());
    this.flight.route[1].dtArrivalScheduled = new Date(startDayTime.format());

    timer(800).subscribe(() => {
      this.flightCardAirline.focus();
    });
  }

  flightClick(id: number) {
    this.flightClickCount++;
    setTimeout(() => {
        if (this.flightClickCount === 1) {
          this.selectFlight(id);
        } else if (this.flightClickCount === 2) {
          this.openCalculation(id);
        }
        this.flightClickCount = 0;
        this.loading = true;
    }, 400);
  }

  async selectFlight(id: number) {
    const xRequestId = this.settingsService.getRandomUuid();
      this.flightForm.form.markAsUntouched();
      this.flightForm.reset();
      this.selectFirstTab();
      await this.restApi.getFlight(id, xRequestId).then(
        (flight) => {
          this.flight = new Flight(+this.settingsService.general.getHomeAirport());
          Object.assign(this.flight, flight);
          this.findAircraftTypeByTail(this.flight.isAhmCalc);
          if (this.flight.route) {
            this.flight.route = [];
            this.destination = [];
            let flag = false;
            flight.route.forEach((el) => {
              const route = new Route();
              Object.assign(route, el);
              route.fixData();
              if (flag) {
                this.destination.push(route);
              }
              this.flight.route.push(route);
              if (route.airportId === this.flight.route[0].airportId) {
                flag = true;
              }
            });
          }
          this.loading = false;
					this.getDocumentListStatus();
        },
        (err) => {
          this.displayError(err);
          this.loading = false;
        }
      );
  }

  // TODO
  // Кейсы при которых фукнция не работает корректно:
  // При рейсе из 2х пунктов, у последнего есть дата вылета (идут кривые данные
  // из выгрузка от АП), ломается логика, надо убрать проверку времени вылета
  // для последнего пункта маршрута
  checkFligthDataCorrect(): boolean {
    let previosPoint: Route = null;
    let currentPoint: Route = null;
    let error = '';
    this.flight.route.forEach((item) => {
      if (previosPoint == null) {
        previosPoint = item;
      } else {
        currentPoint = item;
        if (previosPoint.dtDepartureShow > currentPoint.dtArrivalShow) {
          error +=
            '<strong>' +
            previosPoint.displayName +
            '-' +
            currentPoint.displayName +
            '</strong>' +
            '&nbsp;' +
            this.globalI18n.getMessage(
              ModuleName.WeightBalance,
              'flightCardRouteErrorArrival'
            ) +
            '<br>';
        }
        if (currentPoint.dtArrivalShow && currentPoint.dtDepartureShow) {
          if (currentPoint.dtArrivalShow > currentPoint.dtDepartureShow) {
            error +=
              '<strong>' +
              currentPoint.displayName +
              '</strong>' +
              '&nbsp;' +
              this.globalI18n.getMessage(
                ModuleName.WeightBalance,
                'flightCardRouteErrorDeparture'
              ) +
              '<br>';
          }
        }
        previosPoint = item;
      }
    });
    this.flightCardRouteErrorMessage = error;
    if (error.length === 0) {
      this.flightCardRouteErrorClosed = true;
      return true;
    } else {
      this.flightCardRouteErrorClosed = false;
      return false;
    }
  }

  addFlight() {
    const xRequestId = this.settingsService.getRandomUuid();

    // if (!this.checkFligthDataCorrect()) {
    //   return;
    // }
    this.loading = true;
    this.flightForm.form.markAllAsTouched();
    if (this.flightForm.valid) {
      return this.restApi.addFlight(this.flight, xRequestId).subscribe(
        async (data: HttpResponse<any>) => {
          // Получение данных из заголовков ответа
          // идентификатор рейса будет в нем как /flights/110008765
          const location = data.headers.get('Location');
          // Регулярное выражение, в 2 группе будет идентификатор рейса
          const getFlightIdFromHeader = new RegExp('(/flights/)([0-9]*)', 'g');
          const match = getFlightIdFromHeader.exec(location);
          this.flight.id = +match[2];
          // Закрытие окна редактирования карточки рейса
          this.closeFlightCard.nativeElement.click();
          await this.loadFlights().then(async () => {
            // Открытие расчета для вновь созданного рейса
            // await this.openCalculation(match[2]);
            this.loading = false;
          });
        },
        (err) => {
          this.displayError(err);
          this.loading = false;
        }
      );
    } else {
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'missingRequiredFields'
      );
      this.error.errorType = 'error';
      this.loading = false;
      return;
    }
  }

  editFlight(tab?: string) {
    this.viewParametrs.activeTabFlight = tab ? tab : 'route';
  }

  async openCalculation(flightId: number) {
    const xRequestId = this.settingsService.getRandomUuid();

    // Если открыт расчет по AHM или СЗВ для текущего рейса,
    // не нужно повторно загружать данные при выборе рейса
    if (
      this.viewParametrs.activeWindow !== 'flight-list' &&
      this.flight.id === flightId
    ) {
      return;
    }
    this.documentText = '';
    this.calculationCorrect = false;
    // clearInterval(this.ahmLastupdateInterval);
    this.loading = true;
    await this.selectFlight(flightId).then(async () => {
    if (this.flight.isAhmCalc) {
      this.loadImpCodes();
      await this.editCalculationAhm(flightId);
    } else {
      await this.editCalculationManual(flightId);
    }
    });
    document.addEventListener('keypress', this.autoCalculate);
  }

  autoCalculateFunc(event) {
    if (
      event.key === 'Enter' &&
      event.target.nodeName.toLowerCase() !== 'textarea'
    ) {
      this.calculate();
    }
  }

  saveFlight() {
    const xRequestId = this.settingsService.getRandomUuid();

    this.flightForm.form.updateValueAndValidity();
    this.flightForm.form.markAllAsTouched();
    if (this.flightForm.valid) {
      this.loading = true;
      return this.restApi.updateFlight(this.flight, xRequestId).then(
        async (data) => {
          this.closeFlightCard.nativeElement.click();
          this.loadFlights();
          this.loading = false;
          if (this.flight.route) {
            this.destination = [];
            let flag = false;
            this.flight.route.forEach((el) => {
              const route = new Route();
              Object.assign(route, el);
              if (flag) {
                this.destination.push(route);
              }
              if (route.airportId === this.flight.route[0].airportId) {
                flag = true;
              }
            });
          }
          this.calculation.passengers.weights.handLuggage =
            this.flight.loading.handLuggage;
          this.calculationManual.passengers.weights.handLuggage =
            this.flight.loading.handLuggage;
          // Функция очистки расчета при смене бортового номера, необходимо
          // перерисовать колодцы и убрать все введенные данные
          // TODO позже реализовать мягкую очитку, с сохранением загрузки,
          // пассажиров при одинаковом типе ВС
          if (this.viewParametrs.activeWindow !== 'flight-list') {
            if (
              this.calculation.tailId == null ||
              this.calculation.tailId !== this.flight.tailId
            ) {
              this.clearCalculation();
            }
          }
        },
        (err) => {
          this.displayError(err);
          this.loading = false;
        }
      );
    } else {
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'missingRequiredFields'
      );
      this.error.errorType = 'error';
      this.loading = false;
      return;
    }
  }

  // Users
  loadUsers() {
    const xRequestId = this.settingsService.getRandomUuid();

    this.users = [];
    return this.restApi.getUsers(xRequestId).then((data: User[]) => {
      for (const item of data) {
        const user = new User();
        Object.assign(user, item);
        this.users.push(user);
      }
    });
  }

  async chooseMeAsPerformer(id: number) {
    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'takeFlight'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        this.choicePerformer(id, this.username);
        this.flight.assignee = this.username;
        this.autoSave();
      }
    });
  }

  assignFlightsToUser(user, single?: boolean) {
    const assignFlights = async () => {
      if (this.selectFlightsState) {
        // Если активирован режим мультивыбора рейсов
        // назначить все рейсы пользователю
        for (const item of this.flightList) {
          const index = this.markFlightList.indexOf(item.id);
          if (index !== -1) {
            await this.choicePerformer(item.id, user);
            this.markFlightList.splice(index, 1);
          }
        };
      } else if (single && this.flight.id) {
        // Назначение выделенного рейса в списке через диалоговое окно
        await this.choicePerformer(this.flight.id, user);
      }
    };
    // После завершения назначения рейсов/рейса закрыть диалог и обновить
    // список рейсов
    assignFlights().then(() => {
      this.closebutton.nativeElement.click();
      setTimeout(() => this.loadFlights(), 600);
    });
  }

  choicePerformer(id: number, user, reloadFlightList?: boolean) {
    const xRequestId = this.settingsService.getRandomUuid();

    return this.restApi.setUser(id, user, xRequestId);
  }

  async editCalculationAhm(id: number, changeTab?: boolean) {
    const xRequestId = this.settingsService.getRandomUuid();

    this.viewParametrs.activeWindow = 'calculation';
    // clearInterval(this.loadFlightsInterval);
    // По умолчанию вкладка всегда переключается на 1 в форме
    // если передано значение, то остаемся на текущий вкладке
    if (changeTab == null) {
      this.viewParametrs.activeTab = 'flight-tab-info';
    }
    this.calculation = new CalculationAhm();
    //???
    // this.calculationCrewComposition = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});
    this.deltaDowDoi = new DeltaDowDoi();

    await this.loadAhmData();

    this.ahmLastupdateInterval.forEach((interval) => {
      clearInterval(interval);
    });
    const intervalId = setInterval(() => {
      if (this.flight?.id) {
        this.getAhmLastupdate(this.flight.tailId);
      }
    }, 30000);
    this.ahmLastupdateInterval.push(intervalId);

    this.dataWell = this.ahmData.centreOfGravityChart[0];
    this.chartData = [];

    await this.restApi.getCalculation(id, xRequestId).then(
      async (data) => {
        this.userAction = USER_ACTIONS.EDIT_CALCULATION_AHM;
        this.calculation = JSON.parse(JSON.stringify(data));
        // Если ботовой номер в расчете не совпадете с бортовым в рейсе,
        // то расчет не актуальный, все данные нужно очистить
        if (
          this.calculation.tailId &&
          this.calculation.tailId !== 0 &&
          this.calculation.tailId !== this.flight.tailId
        ) {
          this.clearCalculation();
          return;
        }

        this.calculation.passengers.cabinArea = [];
        if (data.passengers.cabinArea) {
          data.passengers.cabinArea.forEach((el) => {
            el.index = this.ahmData.configurations
              .find((conf) => conf.name === this.calculation.configuration)
              ?.sections.find((section) => section.name === el.name).index;
            this.calculation.passengers.cabinArea.push(
              Object.assign(new CabinArea(), el)
            );
          });
        }
        // Если данные были введены но не произведен расчет, не будет
        // идентификатора бортового номера, необходимо отрисовать только
        // колодец, так как нулевой расчет отобразит некоректный график
        if (this.calculation.tailId) {
          await this.restApi.getChart(this.calculation, xRequestId).then(
            (charts) => {
              this.chartData = charts;
              this.dataWell = this.calculation.centreOfGravityChart;
            },
            (error) => {
              this.chartData = [];
            }
          );
        }
        for (const crew of this.crewComposition) {
          if (
            crew.cabin === this.calculation.crew.schema.cabin &&
            crew.cockpit === this.calculation.crew.schema.cockpit &&
            crew.name === this.calculation.crew.schema.name
          ) {
            //???
            // this.calculationCrewComposition = new DowChangesCrew(crew);
            this.deltaDowDoi.standard.dow = crew.dow;
            this.deltaDowDoi.standard.doi = crew.doi;
            break;
            // } else if (crew.cabin === this.calculation.crew.schema.cabin &&
            //            crew.cockpit === this.calculation.crew.schema.cockpit &&
            //            !crew.extra) {
            //   //???
            //   // this.calculationCrewComposition = new DowChangesCrew(crew);
            //   this.deltaDowDoi.standard.dow = crew.dow;
            //   this.deltaDowDoi.standard.doi = crew.doi;
            //   break;
          }
        }
      },
      (error) => {
        this.userAction = USER_ACTIONS.ADD_CALCULATION;
        this.enterFactDowDoiAutomatically = true;
        this.setDefaultCalculation();
      }
    );

    this.documentEditions = [];
    this.currentDocumentEditions = null;
    this.currentTelegramView = null;
    this.getDocumentEditions();

    this.createGravity(this.dataWell);

    await this.getDeltaDowDoi(xRequestId);
    if (
      (this.calculation.dow === 0 && this.calculation.doi === 0) ||
      (this.calculation.dow === this.deltaDowDoi.calculated.dow &&
        this.calculation.doi === this.deltaDowDoi.calculated.doi)
    ) {
      this.enterFactDowDoiAutomatically = true;
      this.setDowDoiCalculationFromDelta();
    } else {
      this.enterFactDowDoiAutomatically = false;
    }

    this.checkCalculationCorrect();

    this.loading = false;
  }

  createGravity(dataWell) {
    if (this.gravityCentreBlock) {
      if (this.gravityCentreBlock) {
        const width = this.gravityCentreBlock.nativeElement.clientWidth;
        this.context = createCanvas(
          width,
          width,
          this.gravityCentreBlock.nativeElement
        );
        this.incorrectCalculation = drawGravity(
          width,
          width,
          dataWell,
          this.chartData,
          this.ahmData,
          this.context,
          this.calculation.fuel.taxi,
          new WeightLimit(
            this.calculation.mzfw,
            this.calculation.mtow,
            this.calculation.mlw,
            this.ahmData.rampTaxiWeight
          )
        );
      }
    }

    if (this.gravityCentreBlockBig) {
      const height = document.documentElement.clientHeight - 100;
      this.contextBig = createCanvas(
        height,
        height,
        this.gravityCentreBlockBig.nativeElement
      );
      this.incorrectCalculation = drawGravity(
        height,
        height,
        dataWell,
        this.chartData,
        this.ahmData,
        this.contextBig,
        this.calculation.fuel.taxi,
        new WeightLimit(
          this.calculation.mzfw,
          this.calculation.mtow,
          this.calculation.mlw,
          this.ahmData.rampTaxiWeight
        )
      );
    }
  }

  async editCalculationManual(id) {
    const xRequestId = this.settingsService.getRandomUuid();

    this.viewParametrs.activeWindow = 'calculation-manual';
    // clearInterval(this.loadFlightsInterval);
    this.calculationManual = new CalculationManual();
    this.calculationManual.flightId = this.flight.id;

    await this.restApi.getCalculationManual(id, xRequestId).then(
      async (data) => {
        this.userAction = USER_ACTIONS.EDIT_CALCULATION_MANUAL;
        this.calculationManual = JSON.parse(JSON.stringify(data));
        this.calculationManual.passengers.cabinArea = [];
        data.passengers.cabinArea.forEach((el) => {
          el.index = this.ahmData.configurations
            .find((conf) => conf.name === this.calculation.configuration)
            .sections.find((section) => section.name === el.name).index;
          this.calculationManual.passengers.cabinArea.push(
            Object.assign(new CabinArea(), el)
          );
        });
        for (const crew of this.crewComposition) {
          if (
            crew.cabin === this.calculationManual.crew.schema.cabin &&
            crew.cockpit === this.calculationManual.crew.schema.cockpit //&&
            //crew.extra === this.calculationManual.crew.schema.extra
          ) {
            //???
            // this.calculationCrewComposition = new DowChangesCrew(crew);
            this.deltaDowDoi.standard.dow = crew.dow;
            this.deltaDowDoi.standard.doi = crew.doi;
            break;
          } else if (
            crew.cabin === this.calculationManual.crew.schema.cabin &&
            crew.cockpit === this.calculationManual.crew.schema.cockpit &&
            !crew.extra
          ) {
            //???
            // this.calculationCrewComposition = new DowChangesCrew(crew);
            this.deltaDowDoi.standard.dow = crew.dow;
            this.deltaDowDoi.standard.doi = crew.doi;
            break;
          }
        }
      },
      (error) => {
        this.userAction = USER_ACTIONS.ADD_CALCULATION_MANUAL;
        this.calculationManual.passengers.weights.handLuggageIncluded = true;
        this.calculationManual.passengers.weights.handLuggage =
          this.flight.loading.handLuggage || null;
      }
    );

    this.documentEditions = [];
    this.currentDocumentEditions = null;
    this.currentTelegramView = null;
    this.getDocumentEditions();

    this.loading = false;
  }

  async loadAhmData() {
    const xRequestId = this.settingsService.getRandomUuid();

    await this.restApi
      .getAhmData(this.flight.tailId, this.flight.id, xRequestId)
      .then(
        (data) => {
          this.ahmData = new AhmData();
          Object.assign(this.ahmData, data);

          const allLocations = this.getAllLocations(
            this.ahmData.holdsAndCompartments
          );

          this.locations = {};
          this.uldTypes = [];
          if (this.ahmData.uldTypes && this.ahmData.uldTypes.length > 0) {
            this.ahmData.uldTypes.forEach((uld) => {
              this.uldTypes.push(uld);
              this.locations[uld.name] = allLocations.filter(
                (bay) => bay.uldTypes && bay.uldTypes.includes(uld.name)
              );
            });
          }

          const bulkUld = new UldTypes({
            name: 'BULK',
            weight: 0,
            maxWeight: 0,
            maxVolume: 0,
          });
          this.uldTypes.push(bulkUld);
          this.locations['BULK'] = allLocations.filter(
            (bay) => bay.type.toLowerCase() === 'bulk'
          );

          this.crewComposition = [];
          if (
            this.ahmData.dowChanges.crew &&
            this.ahmData.dowChanges.crew.length > 0
          ) {
            this.ahmData.dowChanges.crew.forEach((el) => {
              this.crewComposition.push(new DowChangesCrew(el));
            });
          }
        },
        (err) => {
          this.displayError(err);
        }
      );
  }

  async changeCrewComposition(composition) {
    const xRequestId = this.settingsService.getRandomUuid();

    this.calculation.crew = Object.assign({}, composition);
    this.deltaDowDoi.standard.dow =
      composition.dow || this.deltaDowDoi.standard.dow;
    this.deltaDowDoi.standard.doi =
      composition.doi || this.deltaDowDoi.standard.doi;
    if (this.enterFactDowDoiAutomatically) {
      this.calculation.dow = this.deltaDowDoi.calculated.dow;
      this.calculation.doi = this.deltaDowDoi.calculated.doi;
    }
    await this.getDeltaDowDoi(xRequestId);
    this.setDowDoiCalculationFromDelta();
    this.autoSave();
  }

  async clearCalculation() {
    const id = this.calculation.id;
    const edno = this.calculation.edno;
    await this.loadAhmData();
    this.calculation = new CalculationAhm();
    this.calculation.id = id;
    this.calculation.edno = edno;
    //???
    // this.calculationCrewComposition = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});
    this.deltaDowDoi = new DeltaDowDoi();
    await this.setDefaultCalculation();
    this.enterFactDowDoiAutomatically = true;
    this.dataWell = this.ahmData.centreOfGravityChart[0];
    this.chartData = [];
    this.createGravity(this.dataWell);
    // if (this.gravityCentreBlock) {
    //   const width = this.gravityCentreBlock.nativeElement.clientWidth;
    //   this.incorrectCalculation = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context, this.calculation.fuel.taxi);
    // }
    // const height = document.documentElement.clientHeight - 100;
    // this.incorrectCalculation = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig, this.calculation.fuel.taxi);
    // Перед формированием документов добавление, если расчетов не было
    if (this.userAction === USER_ACTIONS.ADD_CALCULATION) {
      await this.addCalculateWithoutCheck();
    } else {
      await this.saveCalculateWithoutCheck();
    }
    this.checkCalculationCorrect();
  }

  async reloadAhmData() {
    const xRequestId = this.settingsService.getRandomUuid();

    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'reloadAhmData'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        await this.clearCalculation();
        // const id = this.calculation.id;
        // const edno = this.calculation.edno;
        // await this.loadAhmData();
        // this.calculation = new CalculationAhm();
        // this.calculation.id = id;
        // this.calculation.edno = edno;
        // this.calculationCrewComposition = new DowChangesCrew({cockpit: 0, cabin: 0, extra: 0, dow: 0, doi: 0});
        // this.deltaDowDoi = new DeltaDowDoi();
        // this.setDefaultCalculation();
        // this.calculation.ahmLastupdate = this.ahmData.lastupdate;

        // const dataWell = this.ahmData.centreOfGravityChart[0];

        // this.chartData = [];

        // if (this.gravityCentreBlock) {
        //   const width = this.gravityCentreBlock.nativeElement.clientWidth;
        //   this.incorrectCalculation = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context, this.calculation.fuel.taxi);
        // }

        // const height = document.documentElement.clientHeight - 100;
        // this.incorrectCalculation = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig, this.calculation.fuel.taxi);
        // this.checkCalculationCorrect();
      }
    });
  }

  getAhmLastupdate(id) {
    const xRequestId = this.settingsService.getRandomUuid();

    this.restApi.getAhmData(id, this.flight.id, xRequestId).then(
      (data) => {
        this.ahmData.lastupdate = data.lastupdate;
      },
      (err) => {
        this.displayError(err);
      }
    );
  }

  setDefaultCalculation() {

    this.calculation.flightId = this.flight.id;
    this.calculation.tailsAhmDataId = this.ahmData.id;
    this.calculation.mtow = this.ahmData.takeOffWeight;
    this.calculation.mlw = this.ahmData.landingWeight;
    this.calculation.ahmLastupdate = this.ahmData.lastupdate;
    this.calculation.fuel.taxi = this.ahmData.taxiFuel;
    this.calculation.fuel.onBoard = null;
    this.calculation.fuel.trip = null;
    this.calculation.fuel.ballast = null;
    this.calculation.mzfw = this.ahmData.zeroFuelWeight;
    // Установка плотности топлива по умолчанию, если задано в AHM
    for (const fuel of this.ahmData.effectOfFuel) {
      if (fuel.default) {
        this.calculation.fuelDensity = fuel.density;
        break;
      }
    }
    //???
    // this.calculation.crew.cockpit.weight = this.ahmData.standartWeights.crew.cockpit.weight;
    // this.calculation.crew.cockpit.handLuggage = this.ahmData.standartWeights.crew.cockpit.handLuggage;
    // this.calculation.crew.cabin.weight = this.ahmData.standartWeights.crew.cabin.weight;
    // this.calculation.crew.cabin.handLuggage = this.ahmData.standartWeights.crew.cabin.handLuggage;
    //???
    // Поиск стандартного экипажа
    for (const crew of this.ahmData.dowChanges.crew) {
      if (crew.standard) {
        //???
        // this.calculationCrewComposition = new DowChangesCrew(crew);
        this.deltaDowDoi.standard.dow = crew.dow;
        this.deltaDowDoi.standard.doi = crew.doi;
        this.calculation.crew.schema.cabin = crew.cabin;
        this.calculation.crew.schema.cockpit = crew.cockpit;
        this.calculation.crew.schema.name = crew.name;
        //??
        // this.calculation.crew.schema.extra = crew.extra;
        // this.calculation.crew.cabin.amount = crew.cabin;
        // this.calculation.crew.cockpit.amount = crew.cockpit;
        //??
        this.calculation.dow = this.deltaDowDoi.calculated.dow;
        this.calculation.doi = this.deltaDowDoi.calculated.doi;
        break;
      }
    }
    // Если стандартного экипажа нету, берет первый
    if (
      this.calculation.dow === null &&
      this.ahmData.dowChanges.crew.length > 0
    ) {
      //???
      // this.calculationCrewComposition = new DowChangesCrew(this.ahmData.dowChanges.crew[0]);
      this.deltaDowDoi.standard.dow = this.ahmData.dowChanges.crew[0].dow;
      this.deltaDowDoi.standard.doi = this.ahmData.dowChanges.crew[0].doi;
      this.calculation.crew.schema.cabin =
        this.ahmData.dowChanges.crew[0].cabin;
      this.calculation.crew.schema.cockpit =
        this.ahmData.dowChanges.crew[0].cockpit;
      this.calculation.crew.schema.name = this.calculation.crew.schema.name;
      //??
      // this.calculation.crew.schema.extra = this.ahmData.dowChanges.crew[0].extra;
      // this.calculation.crew.cabin.amount = this.ahmData.dowChanges.crew[0].cabin;
      // this.calculation.crew.cockpit.amount = this.ahmData.dowChanges.crew[0].cockpit;
      //??
      this.calculation.dow = this.deltaDowDoi.calculated.dow;
      this.calculation.doi = this.deltaDowDoi.calculated.doi;
    }

    if (
      this.ahmData.configurations &&
      this.ahmData.configurations.length === 1
    ) {
      this.changeVersionAhm(this.ahmData.configurations[0]);
    }

    const season = this.getSeason(this.flight.homeRoute.dtDepartureScheduled);

    this.calculation.passengers.weights = {
      ...this.ahmData.standartWeights.passengers[season],
    };
    this.calculation.passengers.weights.handLuggageIncluded =
      this.ahmData.standartWeights.passengers.handLuggageIncluded;
    this.calculation.passengers.weights.handLuggage =
      this.flight.loading.handLuggage || null;

    // Добавление записей в таблицу распределения груза при первом расчете
    // функционал отключен, так как центровщики сначала вводят пассажиров и
    // топливо, далее делают предварительный расчет и после понимания текущей
    // схемы загрузки начинают распределять вес в переднюю или заднюю часть ВС.
    // Сейчас приходится сначала удалять пункту по умолчанию и потом их добавлять.
    // for (let i = 0; i < this.flight.route.length; i++) {
    //   let route = this.flight.route[i];
    //   if (this.flight.isDeparture(route.airportId)) {
    //     if (route.loading.baggageWeight > 0) {
    //       this.addLoadHoldsAndCompartments({destination: route.airportIata, type: 'Baggage', weight: route.loading.baggageWeight});
    //     }
    //     if (route.loading.cargo > 0) {
    //       this.addLoadHoldsAndCompartments({destination: route.airportIata, type: 'Cargo', weight: route.loading.cargo});
    //     }
    //     if (route.loading.mail > 0) {
    //       this.addLoadHoldsAndCompartments({destination: route.airportIata, type: 'Mail', weight: route.loading.mail});
    //     }
    //   }
    // }

    const pantry = this.ahmData.dowChanges.pantry.find((el) => el.standard);
    if (pantry) {
      this.changeDowChanges(pantry, 'pantry');
    }

    const water = this.ahmData.dowChanges.potableWater.find(
      (el) => el.standard
    );
    if (water) {
      this.changeDowChanges(water, 'potableWater');
    }

    this.setDowDoiCalculationFromDelta();
  }

  /**
   * Функция установки компановки
   * @param { string } event вариант компановки
   */
  changeVersionAhm(event) {

    if (event) {
      this.calculation.configuration = event.name;
      this.calculation.passengers.cabinArea = [];
      event.sections.forEach((section) => {
        const area = new CabinArea(section);
        this.calculation.passengers.cabinArea.push(area);
      });
    }
    return;
  }

  changeDowChanges(event, name) {
    if (event) {
      this.deltaDowDoi[name].standardDow =
        this.ahmData.dowChanges[name].find((el) => el.standard)?.weight || 0;
      this.deltaDowDoi[name].standardDoi =
        this.ahmData.dowChanges[name].find((el) => el.standard)?.index || 0;
      if (event.standard) {
        this.calculation.dowChanges[name].code = event.code;
        this.deltaDowDoi[name].dow = 0;
        this.deltaDowDoi[name].doi = 0;
      } else {
        this.calculation.dowChanges[name].code = event.code;
        this.deltaDowDoi[name].dow = +event.weight.toFixed(3);
        this.deltaDowDoi[name].doi = +event.index.toFixed(3);
      }
    } else {
      this.calculation.dowChanges[name].code = '';
      this.deltaDowDoi[name].dow = 0;
      this.deltaDowDoi[name].doi = 0;
      this.deltaDowDoi[name].standardDow = 0;
      this.deltaDowDoi[name].standardDoi = 0;
    }

    // if (this.enterFactDowDoiAutomatically) {
    // this.calculation.dow = this.deltaDowDoi.calculated.dow;
    // this.calculation.doi = this.deltaDowDoi.calculated.doi;
    // }
    // this.calculation.dow = this.deltaDowDoi.calculated.dow;
    // this.calculation.doi = this.deltaDowDoi.calculated.doi;
  }

  setDowDoiCalculationFromDelta() {
    if (this.enterFactDowDoiAutomatically) {
      this.calculation.dow = this.deltaDowDoi.calculated.dow;
      this.calculation.doi = this.deltaDowDoi.calculated.doi;
    }
  }

  async getDeltaDowDoi(xRequestId) {
    if (this.calculation.fuel.ballast > 0) {
      await this.restApi.getDeltaBallast(this.calculation, xRequestId).then(
        (ballast) => {
          this.deltaDowDoi.ballast = {
            dow: ballast.deltaDow,
            doi: ballast.deltaDoi,
          };
        },
        (err) => {
          this.displayError(err);
        }
      );
    } else {
      this.deltaDowDoi.ballast = { dow: 0, doi: 0 };
    }
    if (this.calculation.crew.schema.name) {
      await this.restApi.getDeltaCrew(this.calculation, xRequestId).then(
        (crew) => {
          this.deltaDowDoi.crew = { dow: crew.deltaDow, doi: crew.deltaDoi };
        },
        (err) => {
          this.displayError(err);
        }
      );
    } else {
      this.deltaDowDoi.crew = { dow: 0, doi: 0 };
    }
    const pantry = this.ahmData.dowChanges.pantry.find(
      (el) => el.code === this.calculation.dowChanges.pantry.code
    );
    this.changeDowChanges(pantry, 'pantry');
    const potableWater = this.ahmData.dowChanges.potableWater.find(
      (el) => el.code === this.calculation.dowChanges.potableWater.code
    );
    this.changeDowChanges(potableWater, 'potableWater');
  }

  async calculate() {
    const xRequestId = this.settingsService.getRandomUuid();

    this.calculatedAnimation = true;
    await this.getDeltaDowDoi(xRequestId);
    this.setDowDoiCalculationFromDelta();
    return new Promise((resolve) => {
      this.documentText = '';
      this.calculation.lastupdate = new Date();
      this.calculation.tailId = this.flight.tailId;
      this.restApi.calculate(this.calculation, xRequestId).subscribe(
        async (data) => {
          this.calculation = JSON.parse(JSON.stringify(data));
          this.calculation.lastupdate = new Date();
          //Object.assign(this.calculation, data);
          this.calculation.passengers.cabinArea = [];
          data.passengers.cabinArea.forEach((el) => {
            el.index = this.ahmData.configurations
              .find((conf) => conf.name === this.calculation.configuration)
              .sections.find((section) => section.name === el.name).index;
            this.calculation.passengers.cabinArea.push(
              Object.assign(new CabinArea(), el)
            );
          });
          await this.restApi.getChart(this.calculation, xRequestId).then(
            (charts) => {
              this.chartData = charts;
              this.dataWell = this.calculation.centreOfGravityChart;
              this.createGravity(this.dataWell);
              // if (this.gravityCentreBlock) {
              //   const width = this.gravityCentreBlock.nativeElement.clientWidth;
              //   this.incorrectCalculation =
              //     drawGravity(width, width, this.calculation.centreOfGravityChart, this.chartData, this.ahmData, this.context, this.calculation.fuel.taxi, new WeightLimit(this.calculation.mzfw, this.calculation.mtow, this.calculation.mlw, this.ahmData.rampTaxiWeight));
              // }
              // const height = document.documentElement.clientHeight - 100;
              // this.incorrectCalculation =
              //   drawGravity(height, height, this.calculation.centreOfGravityChart, this.chartData, this.ahmData, this.contextBig, this.calculation.fuel.taxi, new WeightLimit(this.calculation.mzfw, this.calculation.mtow, this.calculation.mlw, this.ahmData.rampTaxiWeight));
              this.checkCalculationCorrect();
            },
            (err) => {
              this.calculatedAnimation = false;
              this.displayError(err);
            }
          );
          this.calculatedAnimation = false;
          resolve(this.calculation);
        },
        (err) => {
          this.calculatedAnimation = false;
          this.displayError(err);
        }
      );
    });
  }

  async validationCalculate(calc, makeDocuments?: boolean) {

    this.error.errorMessage = '';

    // Check version
    if (!calc.configuration) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'enterVersionAircraft'
      );
      return false;
    }

    // Check fuel
    if (
      !calc.fuel.onBoard ||
      !calc.fuel.taxi ||
      !calc.fuel.trip ||
      !calc.fuelDensity
    ) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'enterFuelData'
      );
      return false;
    }

    if (
      calc.fuel.ballast > 0 &&
      calc.fuel.onBoard - calc.fuel.taxi - calc.fuel.trip < calc.fuel.ballast
    ) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'fuelWeightDiscrepancy'
      );
      return false;
    }

    // Check composition and DOW/DOI
    if (
      !calc.dow ||
      !calc.doi ||
      (!calc.crew.schema.cabin && !calc.crew.schema.cockpit)
    ) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'enterCrewComposition'
      );
      return false;
    }

    // Check weight
    if (!calc.mtow || !calc.mlw || !calc.mzfw) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'enterWeightData'
      );
      return false;
    }

    // Установлен ли груз на позицию в багажном отсеке
    let errCompartment = false;
    this.calculation.holdsAndCompartments.forEach((compartment) => {
      if (compartment.name === '' || compartment.name == null) {
        errCompartment = true;
      }
    });

    if (errCompartment) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'noLuggageCompartmentsSelected'
      );
      return false;
    }

    // Выбран ли код груза
    this.calculation.holdsAndCompartments.forEach((compartment) => {
      if (compartment.type === '') {
        errCompartment = true;
      }
    });

    if (errCompartment) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'noLicCode'
      );
      return false;
    }

    // Присутствует ли пункт маршрута в рейсе
    this.calculation.holdsAndCompartments.forEach((compartment) => {
      if (this.destination.length > 0) {
        if (
          this.destination
            .map((item) => {
              return item.displayName;
            })
            .indexOf(compartment.destination) === -1
        ) {
          errCompartment = true;
        }
      }
    });

    if (errCompartment) {
      this.error.errorType = 'error';
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'noDestinationOnRoute'
      );
      return false;
    }

    this.ahmData.holdsAndCompartments.aft.forEach((compartment) => {
      if (
        compartment.maxWeight > 0 &&
        this.getLoadCompartment(compartment.name) > compartment.maxWeight
      ) {
        errCompartment = true;
      }
      compartment.bays.forEach((bay) => {
        if (this.getLoadWeight(bay.name) > bay.maxWeight) {
          errCompartment = true;
        }
      });
    });

    this.ahmData.holdsAndCompartments.fwd.forEach((compartment) => {
      if (
        compartment.maxWeight > 0 &&
        this.getLoadCompartment(compartment.name) > compartment.maxWeight
      ) {
        errCompartment = true;
      }
      compartment.bays.forEach((bay) => {
        if (this.getLoadWeight(bay.name) > bay.maxWeight) {
          errCompartment = true;
        }
      });
    });

    if (errCompartment) {
      this.error.errorType = 'error';
      this.error.errorMessage += this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'compartmentsOverloaded'
      );
      return false;
    }

    let errLimitCompartment = false;
    this.ahmData.holdsAndCompartments.limits.forEach((compartment) => {
      const limit = compartment.compartments.reduce(
        (acc, el) => (acc += this.getLoadCompartment(el)),
        0
      );
      if (limit > compartment.maxWeight) {
        this.error.errorMessage +=
          this.globalI18n.getMessage(
            ModuleName.WeightBalance,
            'summaryWeightCompartment'
          ) +
          ' ' +
          compartment.compartments +
          ' ' +
          this.globalI18n.getMessage(
            ModuleName.WeightBalance,
            'cannotExceedValue'
          ) +
          ' ' +
          compartment.maxWeight +
          '\n';
        errLimitCompartment = true;
      }
    });

    if (errLimitCompartment) {
      this.error.errorType = 'error';
      return false;
    }

    // Check Passengers
    let errPassengers = false;
    this.calculation.passengers.cabinArea.forEach((el) => {
      if (el.maxPassengers < el.passengers.adult) {
        errPassengers = true;
      }
    });

    if (errPassengers) {
      this.error.errorType = 'error';
      this.error.errorMessage += this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'maxValueCabinSectionExceeded'
      );
      return false;
    }

    // Проверка на превышение весов только в момент создания документов для
    // экипажа
    if (makeDocuments) {
      if (calc.tow > calc.mtow) {
        this.error.errorType = 'error';
        this.error.errorMessage += this.globalI18n.getMessage(
          ModuleName.WeightBalance,
          'maxTow'
        );
        return false;
      }

      if (calc.lw > calc.mlw) {
        this.error.errorType = 'error';
        this.error.errorMessage += this.globalI18n.getMessage(
          ModuleName.WeightBalance,
          'maxLw'
        );
        return false;
      }
    }

    // Check payload and seating
    let warning = false;

    if (
      this.allLoadWeight() !==
      this.flight.loading.luggage +
        this.flight.loading.cargo +
        this.flight.loading.mail
    ) {
      this.error.errorMessage +=
        this.globalI18n.getMessage(ModuleName.WeightBalance, 'warningPayload') +
        '\n';
      warning = true;
    }
    //???
    // if (this.placesDeclared() + this.calculation.crew.additional.inCabin != this.getTotalPassenger()) {
    //   this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'warningZoneSeating') + '\n';
    //   warning = true;
    // }

    let validate = true;

    if (warning) {
      this.error.errorMessage += this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'continue'
      );
      this.error.errorType = 'warning';
      this.modalType = 'setAnswer';
      await this.waitAnswer().then(async (res) => {
        if (res) {
          validate = true;
        } else {
          validate = false;
        }
      });
    }

    return validate;
  }

  async validationManualCalculate(calc, makeDocuments?: boolean) {

    this.error.errorMessage = '';

    let warning = false;

    // Check Passengers
    // if (calc.passengers.weights.adult + calc.passengers.weights.female + calc.passengers.weights.child > calc.ll) {
    //   this.error.errorMessage += this.globalI18n.getMessage(ModuleName.WeightBalance, 'zoneSeatindError') + '\n';
    //   warning = true;
    // }

    // Check Passengers
    if (!calc.seats.max) {
      if (
        this.flight.loading.adult + this.flight.loading.child >
        calc.seats.current
      ) {
        this.error.errorMessage +=
          this.globalI18n.getMessage(
            ModuleName.WeightBalance,
            'zoneSeatindError'
          ) + '\n';
        warning = true;
      }
    } else {
      if (
        this.flight.loading.adult + this.flight.loading.child >
        calc.seats.max
      ) {
        this.error.errorMessage +=
          this.globalI18n.getMessage(
            ModuleName.WeightBalance,
            'zoneSeatindError'
          ) + '\n';
        warning = true;
      }
      if (calc.seats.current > calc.seats.max) {
        this.error.errorMessage +=
          this.globalI18n.getMessage(
            ModuleName.WeightBalance,
            'totalPassengersMoreMax'
          ) + '\n';
        warning = true;
      }
    }
    let validate = true;

    if (warning) {
      this.error.errorMessage += this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'continue'
      );
      this.error.errorType = 'warning';
      this.modalType = 'setAnswer';
      await this.waitAnswer().then(async (res) => {
        if (res) {
          validate = true;
        } else {
          validate = false;
        }
      });
    }

    return validate;
  }

  async addCalculate() {
    const xRequestId = this.settingsService.getRandomUuid();

    const res = await this.validationCalculate(this.calculation);
    if (res) {
      this.calculatedAnimation = true;
      await this.calculate().then(
        async () => {
          await this.restApi.addCalculate(this.calculation, xRequestId).then(
            async (data) => {
              // Получение данных из заголовков ответа
              const location = data.headers.get('Location');
              // Регулярное выражение, в 2 группе будет идентификатор расчета
              const getIdFromHeader = new RegExp(
                '(/calculations/)([0-9]*)',
                'g'
              );
              this.userAction = USER_ACTIONS.EDIT_CALCULATION_AHM;
              this.calculation.id = +getIdFromHeader.exec(location)[2];
              this.calculatedAnimation = false;
              await this.editCalculationAhm(this.flight.id, false);
            },
            (err) => {
              this.calculatedAnimation = false;
              this.displayError(err);
            }
          );
        },
        (err) => {
          this.calculatedAnimation = false;
          this.displayError(err);
        }
      );
    }
  }

  async addCalculateWithoutCheck() {
    const xRequestId = this.settingsService.getRandomUuid();

    await this.restApi.addCalculate(this.calculation, xRequestId).then(
      async (data) => {
        // Получение данных из заголовков ответа
        const location = data.headers.get('Location');
        // Регулярное выражение, в 2 группе будет идентификатор расчета
        const getIdFromHeader = new RegExp('(/calculations/)([0-9]*)', 'g');
        this.userAction = USER_ACTIONS.EDIT_CALCULATION_AHM;
        this.calculation.id = +getIdFromHeader.exec(location)[2];
      },
      (err) => {
        this.displayError(err);
      }
    );
  }

  async addCalculateManual() {
    const xRequestId = this.settingsService.getRandomUuid();
    const res = await this.validationManualCalculate(this.calculationManual);
    if (res) {
      await this.restApi.updateFlight(this.flight, xRequestId);
      this.restApi
        .addCalculateManual(this.calculationManual, xRequestId)
        .subscribe(
          async (data) => {
            // Получение данных из заголовков ответа
            const location = data.headers.get('Location');
            // Регулярное выражение, в 2 группе будет идентификатор расчета
            const getIdFromHeader = new RegExp(
              '(/manual_loadsheet/)([0-9]*)',
              'g'
            );
            const id = getIdFromHeader.exec(location)[2];
            this.calculationManual.id = +id;
            this.userAction = USER_ACTIONS.EDIT_CALCULATION_MANUAL;
            this.editCalculationManual(this.flight.id);
          },
          (err) => {
            this.displayError(err);
          }
        );
    }
  }

  async addCalculateManualWithoutCheck() {
    const xRequestId = this.settingsService.getRandomUuid();

    await this.restApi.updateFlight(this.flight, xRequestId);
    this.restApi
      .addCalculateManual(this.calculationManual, xRequestId)
      .subscribe(
        async (data) => {
          // Получение данных из заголовков ответа
          const location = data.headers.get('Location');
          // Регулярное выражение, в 2 группе будет идентификатор расчета
          const getIdFromHeader = new RegExp(
            '(/manual_loadsheet/)([0-9]*)',
            'g'
          );
          const id = getIdFromHeader.exec(location)[2];
          this.calculationManual.id = +id;
          this.userAction = USER_ACTIONS.EDIT_CALCULATION_MANUAL;
        },
        (err) => {
          this.displayError(err);
        }
      );
  }

  async saveCalculate(id, res?) {
    const xRequestId = this.settingsService.getRandomUuid();

    res = res || (await this.validationCalculate(this.calculation));
    if (res) {
      this.calculatedAnimation = true;
      await this.calculate().then(async () => {
        await this.restApi
          .updateCalculate(id, this.calculation, xRequestId)
          .then(
            () => {
              this.calculatedAnimation = false;
            },
            (err) => {
              this.calculatedAnimation = false;
              this.displayError(err);
            }
          );
      });
    }
  }

  async saveCalculateWithoutCheck() {
    const xRequestId = this.settingsService.getRandomUuid();

    await this.restApi.updateCalculate(
      this.calculation.id,
      this.calculation,
      xRequestId
    );
  }

  async saveCalculateManual(id) {
    const xRequestId = this.settingsService.getRandomUuid();
    const res = await this.validationManualCalculate(this.calculationManual);
    if (res) {
      await this.restApi.updateFlight(this.flight, xRequestId);
      this.restApi
        .updateCalculateManual(id, this.calculationManual, xRequestId)
        .then(
          () => {},
          (err) => {
            this.displayError(err);
          }
        );
    }
  }

  async saveCalculateManualWithoutCheck() {
    const xRequestId = this.settingsService.getRandomUuid();

    await this.restApi.updateFlight(this.flight, xRequestId);
    this.restApi.updateCalculateManual(
      this.calculationManual.id,
      this.calculationManual,
      xRequestId
    );
  }

  changeHandBaggage(type: string) {

    if (type === 'ahm') {
      const state = !this.calculation.passengers.weights.handLuggageIncluded;
      this.calculation.passengers.weights.handLuggageIncluded = state;
      if (state) {
        this.calculation.passengers.weights.handLuggage =
          this.flight.loading.handLuggage;
      }
    } else if (type === 'manual') {
      const state =
        !this.calculationManual.passengers.weights.handLuggageIncluded;
      this.calculationManual.passengers.weights.handLuggageIncluded = state;
      if (state) {
        this.calculationManual.passengers.weights.handLuggage =
          this.flight.loading.handLuggage;
      }
    }
  }

  // changeFktIncluded() {
  //
  //   this.calculation.fktIncludedInDowDoi = !this.calculation.fktIncludedInDowDoi;
  // }

  changeNotocNil() {
    this.calculation.notoc.releasedByAnother = !this.calculation.notoc.releasedByAnother;
  }

  async printDocument(url) {
    const xRequestId = this.settingsService.getRandomUuid();

    await this.restApi
      .loadDocument(
        this.flight.id,
        this.currentDocumentEditions,
        url,
        xRequestId
      )
      .subscribe({
        next: (data) => {
          this.documentText = data.text;
          // Пауза перед печатью, что бы ангуляр обновил элемент из которого берется текс для печати
          setTimeout(() => {
            this.print.nativeElement.click();
          }, 250);
        },
        error: (err) => {
          this.displayError(err);
        }
			});
  }

  async printTelegram(tlgName: string) {
    const xRequestId = this.settingsService.getRandomUuid();
    await this.restApi.loadTelegram(this.flight.id, tlgName, xRequestId).subscribe({
      next: (data) => {
        this.documentText = data.message;
        // Пауза перед печатью, что бы ангуляр обновил элемент из которого берется текс для печати
        setTimeout(() => {
          this.print.nativeElement.click();
        }, 250);
      },
      error: (err) => {
        this.displayError(err);
      }
		});
  }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }

  async loadDocument(url: string) {
    const xRequestId = this.settingsService.getRandomUuid();
    await this.restApi
      .loadDocument(
        this.flight.id,
        this.currentDocumentEditions,
        url,
        xRequestId
      )
      .subscribe({
				next: (data) => {
					if (data !== undefined && data.text) {
						this.documentText = data.text;
					} else {
						this.documentText = '';
					}
        },
        error: (err) => {
					this.documentText = '';
          this.displayError(err);
        }
			});
  }

	async documentIsExists(type: string, url: string, document: string): Promise<boolean> {
    const xRequestId = this.settingsService.getRandomUuid();
		let result = false;
		// Запрос содержимого документа или телеграммы
		if (document === 'documents') {
			const data = await firstValueFrom(this.restApi
				.loadDocument(
					this.flight.id,
					this.currentDocumentEditions,
					url,
					xRequestId
				)
			);
			if (data !== undefined && data.text.length > 0) {
				result = true;
			} else {
				result = false;
			}
		} else {
			const xRequestId = this.settingsService.getRandomUuid();
			const data = await firstValueFrom(this.restApi.loadTelegram(this.flight.id, type, xRequestId));
			if (data !== undefined && data.message.length > 0) {
				result = true;
			} else {
				result = false;
			}
		}
		return result;
  }

  async loadTelegram(tlgName: string) {
    const xRequestId = this.settingsService.getRandomUuid();

    await this.restApi.loadTelegram(this.flight.id, tlgName, xRequestId).subscribe({
      next: (data) => {
				if (data !== undefined && data.message) {
					this.documentText = data.message;
				} else {
					this.documentText = '';
				}

      },
      error: (err) => {
				this.documentText = '';
        this.displayError(err);
      }
		});
  }

  openSendMessageDialog(userAction: number, name: string) {
    const xRequestId = this.settingsService.getRandomUuid();
    this.userAction = userAction;
    this.typeSendingDoc = name;
    let type = '';
    if (userAction === USER_ACTIONS.SEND_DOC) {
      type = 'documents';
    } else if (userAction === USER_ACTIONS.SEND_TLG) {
      type = 'messages';
    }
    const airports = this.flight.route.map((route) => {
      return route.airportId;
    });
    this.restApi
      .loadRouteNetworks(
        this.flight.airlineId,
        airports,
        type,
        name,
        xRequestId
      )
      .subscribe({
				next: (data) => {
          this.routeNetworks = data;
        },
        error: (err) => {
          this.displayError(err);
        }
			}
      );
  }

  async setDefaultAddress() {
    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'setDefaultAddress'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        this.openSendMessageDialog(this.userAction, this.typeSendingDoc);
      }
    });
  }

  async deleteAllAddress() {

    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'deleteAllItem'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        this.routeNetworks = [];
      }
    });
  }

  sendTelegram(tlgName: string) {
    this.sendMessageForm.form.markAllAsTouched();
    if (this.sendMessageForm.valid && this.routeNetworks?.length > 0) {
			this.spinSendMessageDialog = true;
      this.loadingType = 'sendingTelegram';
      const xRequestId = this.settingsService.getRandomUuid();
      // Добавление служебного адрес SITATEX для обмена по TypeRU
      // данный адрес не отображается для пользователя
      // Пример адреса: SVXTRS7
      // SVX - идентификатор местоположения (код города/аэропорта)
      // TR - идентификатор функции офиса/системы, сокращение от TypeRU
      // S7 - идентификатор пользователя сети согласно определению IATA
      if (
        tlgName === 'ldm' &&
        this.settingsService.getSitatexTypeRuAddress() !== ''
      ) {
        // 2 - идентификатор канала Sitatex
        this.routeNetworks.push({
          channel: 2,
          address: this.settingsService.getSitatexTypeRuAddress(),
        });
      }
      return this.restApi
        .sendTelegram(this.flight.id, tlgName, this.routeNetworks, xRequestId).subscribe(
					{
						next: () => {
							// this.error.errorMessage = this.globalI18n.getMessage(
							// 	ModuleName.WeightBalance,
							// 	'telegramSent'
							// );
							// this.error.errorType = 'info';
							setTimeout(() => {
								this.closeButtonTelegram.nativeElement.click();
								this.spinSendMessageDialog = false;
							}, 2000);
							this.getDocumentListStatus();
						},
						error: (err) => {
							this.displayError(err);
							this.loadingType = '';
							this.loading = false;
							this.spinSendMessageDialog = false;
						}
					}
        );
    }
  }

	async showAllDocumentsAndTelegramsForSend() {
		const sendItems = [
				{ name: 'documents', itemList: [] },
				{ name: 'messages', itemList: [] }
		];

		DOC_TYPES.ahm.forEach(document => {
			sendItems[0].itemList.push({ type: document.type, url: document.url });
		});

		TLG_TYPES.forEach(telegramName => {
			sendItems[1].itemList.push({ type: telegramName, url: '' });
		});

		// ФОРМИРУЕМ ВСЕ ЧТО НАДО ОТПРАВИТЬ
		const xRequestId = this.settingsService.getRandomUuid();
		const airports = this.flight.route.map((route) => {
      return route.airportId;
    });

		this.routeNetworkListForMassSends = [];
		for (const item of sendItems) {
			for (const docItem of item.itemList) {
				const isExists = await this.documentIsExists(docItem.type, docItem.url, item.name);
				if (!isExists) {
						this.routeNetworkListForMassSends.push({ type: docItem.type, status: false, isCheck: isExists, isActive: isExists, routeNetwork: [] });
				} else {
					await this.restApi.loadRouteNetworks(
						this.flight.airlineId,
						airports,
						item.name,
						docItem.type,
						xRequestId
					).subscribe({
						next: (data) => {
						this.routeNetworkListForMassSends.push({ type: docItem.type, status: false, isCheck: isExists, isActive: isExists, routeNetwork: data });
						},
						error: () => {
						this.routeNetworkListForMassSends.push({ type: docItem.type, status: false, isCheck: isExists, isActive: isExists, routeNetwork: [] });
						}
					});
				}
			}
		};
	}

	getDocumentListStatus() {
		this.documentListSendStatus = [];
		DOC_TYPES.ahm.forEach(item => {
			this.documentListSendStatus.push( { type: item.type, status: false, date: null } );
		})

		TLG_TYPES.forEach(item => {
			this.documentListSendStatus.push( { type: item, status: false, date: null } );
		})

		this.documentListSendStatus.forEach(async item => {
			const result = await this.documentIsSent(item.type);
			item.status = result.status;
			item.date = result.date;
		})
	}

	getDocumentStatus(type: string): boolean {
		let result = false;
		for (let index = 0; index < this.documentListSendStatus.length; index++) {
			if (this.documentListSendStatus[index].type === type) {
				result = this.documentListSendStatus[index].status;
			}
		}
		return result;
	}

	async documentIsSent(type: string):Promise<{ status: boolean, date: Date }> {
		const xRequestId = this.settingsService.getRandomUuid();
		const result = await firstValueFrom(this.restApi.documentIsSent(this.flight.id, type, xRequestId));
		if (result && result.canSend) {
			return {status: result.canSend, date: result.dtSent};
		} else {
			return {status: false, date: null};
		}
	}

	allDocumentsAndTelegramsIsSend(): boolean {
		let result = true;
		this.routeNetworkListForMassSends.forEach(async (docItem) => {
			if (docItem.isCheck && docItem.status === false) {
				result = false;
			}
		});
		return result;
	}

	async sendAllDocumentsAndTelegrams() {
		const xRequestId = this.settingsService.getRandomUuid();
		this.routeNetworkListForMassSends.forEach(async (docItem) => {
			if (docItem.isCheck) {
				this.restApi.sendTelegram(this.flight.id, docItem.type, docItem.routeNetwork, xRequestId).subscribe(
					{
						next: () => {
							docItem.status = true;
							this.getDocumentListStatus();
							if (this.allDocumentsAndTelegramsIsSend) {
								// Close send modal
								setTimeout(() => {
									this.closeButtonSendAllDocument.nativeElement.click();
									this.spinSendMessageDialog = false;
								}, 2000);
							}
						},
						error: (err) => {
							this.displayError(err);
						}
					}
				);
			}
		});
	}

	getRouteNetworkAddressList(addressList: {channel: number, address: string}[]){
		let result = '';
		addressList.forEach(item => {
			if (result !== '') {
				result += ', ';
			}
			result += item.address;
		})
		return result;
	}

	documentIsReleased(): null | string {
		if (this.documentEditions.length > 0) {
			return this.documentEditions[this.documentEditions.length - 1].name;
		}
		return null;
	}

  async createDocuments() {
    const xRequestId = this.settingsService.getRandomUuid();

    if (this.viewParametrs.activeWindow === 'calculation') {
      const res = await this.validationCalculate(this.calculation, true);
      if (res) {
        this.loadingType = 'createDocuments';
        // Перед формированием документов добавление, если расчетов не было
        if (this.userAction === USER_ACTIONS.ADD_CALCULATION) {
          await this.addCalculate();
        } else {
          await this.saveCalculate(this.calculation.id, res);
        }
        if (!this.incorrectCalculation) {
          return this.restApi
            .createDocuments(this.flight.id, xRequestId)
            .subscribe(
              (data) => {
                // После удочного создания новой редакции документов
                // нужно обновить порядковый номер документов в расчете
                this.calculation.edno++;
                this.saveCalculate(this.calculation.id, res);
                this.getDocumentEditions();
                this.loadingType = '';
              },
              (err) => {
                this.displayError(err);
                this.loadingType = '';
              }
            );
        }
        this.loadingType = '';
      }
    } else {
      this.loadingType = 'createDocuments';
      if (this.userAction === USER_ACTIONS.ADD_CALCULATION_MANUAL) {
        await this.addCalculateManual();
      } else {
        await this.saveCalculateManual(this.calculationManual.id);
      }
      return this.restApi.createDocuments(this.flight.id, xRequestId).subscribe(
        (data) => {
          // После удачного создания новой редакции документов
          // нужно обновить порядковый номер документов в расчете
          this.calculationManual.edno++;
          this.saveCalculateManual(this.calculationManual.id);
          this.getDocumentEditions();
          this.loadingType = '';
        },
        (err) => {
          this.displayError(err);
          this.loadingType = '';
        }
      );
    }
  }

  saveDocument(url: string) {
    const xRequestId = this.settingsService.getRandomUuid();

    return this.restApi
      .saveDocument(
        this.flight.id,
        this.currentDocumentEditions,
        url,
        xRequestId
      )
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application' });
          this.fileSaverService.save(
            blob,
            this.flight.airlineIata +
              this.flight.flightNumber +
              '_' +
              this.flight.homeRoute.dtDepartureScheduled +
              '_' +
              url +
              '.pdf'
          );
        },
        (err) => {
          this.displayError(err);
        }
      );
  }

  async saveTelegram(tlgName: string) {
    const xRequestId = this.settingsService.getRandomUuid();

    await this.restApi.loadTelegram(this.flight.id, tlgName, xRequestId).subscribe({
      next: (data) => {
        const blob = new Blob([data.message], { type: 'application' });
        this.fileSaverService.save(
          blob,
          this.flight.airlineIata +
            this.flight.flightNumber +
            '_' +
            this.flight.homeRoute.dtDepartureScheduled +
            '_' +
            tlgName +
            '.txt'
        );
      },
      error: (err) => {
        this.displayError(err);
      }
		});
  }

  async getDocumentEditions() {
    const xRequestId = this.settingsService.getRandomUuid();

    this.currentDocumentView = 0;
    this.documentText = null;
    return await this.restApi
      .getDocumentEditions(this.flight.id, xRequestId)
      .then(
        (data) => {
          this.documentEditions = [];
          data.forEach((item, index) => {
            // для перевода слова
            const wordLast = this.globalI18n.getMessage(
              ModuleName.WeightBalance,
              'Last'
            );
            const doc = new Document(index === data.length - 1, wordLast);
            Object.assign(doc, item);
            this.documentEditions.push(doc);
          });
          // this.documentEditions = data;
          // Установка последней редакции активной в списке документов
          if (this.documentEditions.length > 0) {
            this.currentDocumentEditions =
              this.documentEditions[this.documentEditions.length - 1].edno;
          }
        },
        (err) => {
          this.displayError(err);
        }
      );
  }

	generateLoadProgressList() {
		// Добавление всех стандартынх справочников
		for (const key in this.references) {
			this.loadProgressList.set(key, true);
		}
		// Добавление рабочих столов
		this.loadProgressList.set('workspaces', true);
	}

	completedLoadProgressForItem(item: string) {
		this.loadProgressList.set(item, false);
		this.computeLoadProgressPercent();
	}

	computeLoadProgressPercent() {
		// Общее число элементов в списке
		const allItem = this.loadProgressList.size;
		let itemIsTrue = 0;
		// Найти элементы загрузка которых завершена
		for (const value of this.loadProgressList.values()) {
			if (!value) {
				itemIsTrue++;
			}
		}
		// Расчет процента загруженных элементов из общего колличества
		this.loadProgressPercent = Math.round(itemIsTrue / allItem * 100);

		if (this.loadProgressPercent === 100) {
			this.loadFlights();
		}
	}

  async loadReferences() {
    const xRequestId = this.settingsService.getRandomUuid();
    for (const key in this.references) {
      if (Object.prototype.hasOwnProperty.call(this.references, key)) {
        this.selectLoadAnimation[key] = true;
        if (key === 'aircraft_types') {
          this.restApi.getReference(key, xRequestId).then(
            (aircrafts) => {
              aircrafts.forEach((el) => {
                const aicraft = new ReferanceAircraft();
                Object.assign(aicraft, el);
                this.references[key] = [...this.references[key], aicraft];
              });
							this.completedLoadProgressForItem(key);
            },
            (err) => {
							this.completedLoadProgressForItem(key);
              this.displayError(err);
            }
          );
        } else {
					this.restApi.getReference(key, xRequestId).then(
            (data) => {
					    // TODO переделать все справочники на отдельные геттеры и запросы, мб в отдельный файл вынести
              if (key === 'load_information_codes') {
                data = data.sort((a, b) => a.code.length - b.code.length);
              }
              this.references[key] = Array.apply(null, Array(data.length)).map(
                function () {
                  return new Referance();
                }
              );
              data.forEach((el, index) => {
                Object.assign(this.references[key][index], el);
              });
							this.completedLoadProgressForItem(key);
            },
            (err) => {
							this.completedLoadProgressForItem(key);
              this.displayError(err);
            }
          );
        }
        this.selectLoadAnimation[key] = false;
      }
    }
  }

  async loadImpCodes() {
    const xRequestId = this.settingsService.getRandomUuid();
    for (const key in this.impCodes) {
      if (Object.prototype.hasOwnProperty.call(this.impCodes, key)) {
        this.selectLoadAnimation[key] = true;
        await this.restApi.getReference('imp_codes/' + key, xRequestId).then(
          (data) => {
            this.impCodes[key] = data;
          },
          (err) => {
            this.displayError(err);
          }
        );
        this.selectLoadAnimation[key] = false;
      }
    }
  }

  onScrollToEndNgSelect(name) {

    this.fetchMore(name);
  }

  onScrollNgSelect({ end }, name) {

    if (
      this.selectLoadAnimation[name] ||
      this.references[name].length <= this.buffer[name].length
    ) {
      return;
    }

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.buffer[name].length
    ) {
      this.fetchMore(name);
    }
  }

  private fetchMore(name) {
    const len = this.buffer[name].length;
    const more = this.references[name].slice(len, this.bufferSize[name] + len);
    this.selectLoadAnimation[name] = true;
    this.buffer[name] = this.buffer[name].concat(more);
    this.selectLoadAnimation[name] = false;
  }

  /**
   * Функция обработки значения из редактируемой таблицы
   * @param {string} value Строка, введеная пользователем
   */
  toNumber(value) {
    const num = parseFloat(
      value
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/^(-)|[^0-9.,]+/gi, '$1')
        .replace(/^-+/g, '-')
        .replace(',', '.')
    );
    return num || null;
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirline} item Элемент для поиска
   */
  customSelectSearch(term: string, item) {
    term = term.toLowerCase();
    return term.length < 4
      ? (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
          (item.code &&
            item.code[1] &&
            item.code[1].toLowerCase().indexOf(term) > -1)
      : term.length < 5
      ? item.icao && item.icao.toLowerCase().indexOf(term) > -1
      : (item.name &&
          item.name[0] &&
          item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name &&
          item.name[1] &&
          item.name[1].toLowerCase().indexOf(term) > -1);
  }

  getById(array, id: number) {
    if (!array || array.length === 0) {
      return null;
    }
    const res = array.filter((el) => el.id === id)[0];
    if (res) {
      return res;
    }
    return null;
  }

  getProp(object, id, prop) {
    if (object && object.length > 0 && id) {
      const res = this.getById(object, id);
      if (res && res[prop]) {
        return res[prop];
      }
      return null;
    }
    return null;
  }

  setProperty(prop, value) {
    return (prop = value);
  }

  addItem(array, item) {
    array.push(item);
  }

  loadWindow(target = this.viewParametrs.activeWindow) {
    switch (target) {
      case 'flight-list': {
        this.viewParametrs.activeWindow = target;
        document.removeEventListener('keypress', this.autoCalculate);
        this.loadFlights();
        break;
      }
    }
  }

  changeTab(event: { target: { id: string } }) {

    this.viewParametrs.activeTab = event.target.id;
    switch (event.target.id) {
      case 'flight-tab-detributional':
        this.generateBaysMap();
        break;
      case 'flight-tab-info':
        setTimeout(() => {
          this.createGravity(this.dataWell);
        }, 100);
        break;
    }
  }

  showTab(item: string): boolean {
    return item === this.viewParametrs.activeTab;
  }

  showFlightCardTab(item: string): boolean {

    return item === this.viewParametrs.flightCardActiveTab;
  }

  parseDate(dateString: string, time?): Date {
    if (time && dateString) {
      if (this.userShowTime === TimeFormat.Z) {
        return new Date(dateString + 'T' + time + 'Z');
      } else {
        return new Date(
          dateString + 'T' + time + this.settingsService.timeZone.getUserTimeZoneOffset()
        );
      }
    } else if (!time && dateString) {
      return new Date(
        dateString + 'T' + '00:00:00' +  this.settingsService.timeZone.getUserTimeZoneOffset()
      );
    }
    return null;
  }

  parseTime(date, dateString: string): Date {
    if (date.value && date.value + 'T' + dateString) {
      if (this.userShowTime === TimeFormat.Z) {
        return new Date(date.value + 'T' + dateString + 'Z');
      } else {
        return new Date(
          date.value + 'T' + dateString +  this.settingsService.timeZone.getUserTimeZoneOffset()
        );
      }
    }
    return null;
  }

  addRoutePoint() {

    const num = this.flight.route.length;
    this.flight.route[num] = new Route();
    this.flight.route[num].order = num;
  }

  /* TODO: используется также для таблицы суммарной загрузки, нужно вынести в отдельный файл */
  /**
   * Функция, которая вычисляет общий вес в секции
   * @param {string} section имя секции
   */
  getLoadWeight(section: string): number {
    return functions.getLoadWeight(
      this.calculation.holdsAndCompartments,
      section
    );
    // return this.calculation.holdsAndCompartments
    //   .filter(el => el.name === section)
    //   .reduce((acc, el) => {
    //     return acc += el.weight + el.uldWeight;
    //   }, 0);
  }

  /**
   * Функция получения веса загрузки по типу и точке маршрута
   * @param {string} type тип загрузки
   * @param {string} name точка маршрута
   */
  getLoadWeightByType(type, name = null) {
    if (name) {
      return this.calculation.holdsAndCompartments
        .filter(
          (el) =>
            el?.destination === name &&
            el.type[0]?.toLocaleUpperCase() === type.toLocaleUpperCase()
        )
        .reduce((acc, el) => {
          return (acc += el.weight);
        }, 0);
    } else {
      return this.calculation.holdsAndCompartments
        .filter(
          (el) => el.type[0]?.toLocaleUpperCase() === type.toLocaleUpperCase()
        )
        .reduce((acc, el) => {
          return (acc += el.weight);
        }, 0);
    }
  }

  /**
   * Функция получения веса загрузки по типу и багажнику
   * @param {string} type тип загрузки
   * @param {string} section багажник
   */
  getLoadCompartment(section: string, type: string | null = null): number {
    if (type) {
      return this.calculation.holdsAndCompartments
        .filter(
          (el) =>
            el.name &&
            el.name[0] === section &&
            el.type[0] &&
            el.type[0].toLocaleUpperCase() === type.toLocaleUpperCase()
        )
        .reduce((acc, el) => {
          return (acc += el.weight + el.uldWeight);
        }, 0);
    } else {
      return this.calculation.holdsAndCompartments
        .filter((el) => el.name && el.name[0] === section)
        .reduce((acc, el) => (acc += el.weight + el.uldWeight), 0);
    }
  }

  findOverloadSection(compartment) {
    const names = [];
    let weight = 0;
    if (compartment.bays.length > 0) {
      this.calculation.holdsAndCompartments
        .filter((el) => el.name[0] === compartment.name)
        .forEach((el) => {
          const section = compartment.bays.find((bay) => bay.name === el.name);
          if (
            section &&
            this.getLoadWeight(el.name) > section.maxWeight &&
            !names.includes(el.name)
          ) {
            names.push(el.name);
            weight += this.getLoadWeight(el.name) - section.maxWeight;
          }
        });
    }
    if (this.getLoadWeight(compartment.name) > compartment.maxWeight) {
      names.push(compartment.name);
      weight += this.getLoadWeight(compartment.name) - compartment.maxWeight;
    }
    return { names: names.join(', '), weight };
  }

  allLoadWeight() {
    return this.calculation.holdsAndCompartments.reduce((acc, el) => {
      return el.name && el.name.length > 0 && !el.inDow ? acc + el.weight : acc;
    }, 0);
  }

  addItemByClass(array, item, params?) {

    if (!array) {
      array = [];
    }
    array.push(new item(params));
  }

  /**
   * Функция добавления строки загрузки
   * @param {string} destination направление
   * @param {string} type тип загрузки
   */
  addLoadHoldsAndCompartments(params?) {

    let item;
    if (params) {
      item = new HoldsAndCompartmentsCalc(params);
    } else {
      const uldTypeName = this.uldTypes.length === 1 ? this.uldTypes[0].name : '';
      item = new HoldsAndCompartmentsCalc(
        this.destination && this.destination.length === 1
          ? {
              destination: this.destination[0].displayName,
              uldType: uldTypeName,
            }
          : { uldType: uldTypeName }
      );
    }
    this.calculation.holdsAndCompartments.unshift(item);
    timer(300).subscribe(() => {
      this.unlocatedTypeOfCargo.first.focus();
    });
  }

  getSeason(date) {
    date = new Date(date);
    const lastMarch = new Date('03-31-' + date.getFullYear());
    let day = lastMarch.getDay();

    const startSummer = lastMarch;
    if (day !== 0) {
      startSummer.setDate(startSummer.getDate() - day);
    }

    const lastOctober = new Date('10-31-' + date.getFullYear());
    day = lastOctober.getDay();
    const startWinter = lastOctober;
    if (day !== 0) {
      startWinter.setDate(startWinter.getDate() - day);
    }

    return date > startSummer && date < startWinter ? 'summer' : 'winter';
  }

  async deleteRoute(index) {

    if (this.flight.route.length < 3) {
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'warningRoute'
      );
      this.error.errorType = 'error';
    } else {
      this.error.errorMessage = this.globalI18n.getMessage(
        ModuleName.WeightBalance,
        'deleteItem'
      );
      this.error.errorType = 'warning';
      this.modalType = 'setAnswer';
      await this.waitAnswer().then(async (res) => {
        if (res) {
          this.flight.route.splice(index, 1);
        }
      });
    }

    this.flight.route[0].dtArrivalScheduled = null;
    this.flight.route[0].dtArrivalEstimated = null;
    this.flight.route[0].dtArrivalFact = null;

    this.flight.route[this.flight.route.length - 1].dtDepartureScheduled = null;
    this.flight.route[this.flight.route.length - 1].dtDepartureEstimated = null;
    this.flight.route[this.flight.route.length - 1].dtDepartureFact = null;
  }

  async deleteElement(array, index) {

    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'deleteItem'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        if (this.selectedBays && this.selectedBays.length > 0) {
          this.selectedBays = this.selectedBays.filter(
            (item) => item.name !== array[index].name
          );
        }
        array.splice(index, 1);
      }
    });
  }

  async waitAnswer() {
    const res = await this.checkAnswer();
    return res;
  }

  checkAnswer() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.userAnswer !== null) {
          const answer = this.userAnswer;
          this.clearErrorMess();
          clearInterval(interval);
          resolve(answer);
        }
      }, 500);
    });
  }

  clearErrorMess() {

    this.error.errorMessage = '';
    this.error.errorType = '';
    this.modalType = '';
    this.userAnswer = null;
  }

  changeWeightAndBalanceView(showItem) {

    if (showItem === 'table') {
      this.viewParametrs.chartIsShow = false;
    } else {
      this.viewParametrs.chartIsShow = true;
    }
  }

  clearFilter() {

    this.filterParams = new FilterParams();
    this.filterApply = false;
    this.filterFlight();
  }

  filterApp() {

    this.filterApply = true;
    this.filterFlight();
  }

  filterSwitch() {

    this.filterApply = !this.filterApply;
    this.filterFlight();
  }

  filterFlight() {
    this.showFilter = false;
    this.loadFlights();
  }

  clearFilterParametr(field: string, event) {

    event.stopPropagation();
    if (field === 'date') {
      this.filterParams.start = null;
      this.filterParams.finish = null;
    } else if (field === 'cancelled') {
      this.filterParams.cancelled = false;
    } else if (field === 'deleted') {
      this.filterParams.deleted = false;
    } else {
      this.filterParams[field] = null;
    }
    if (this.quickSearchCheck()) {
      this.filterApply = false;
    }
    this.filterFlight();
  }

  quickSearchCheck() {
    const newFilter = new FilterParams();
    return JSON.stringify(this.filterParams) === JSON.stringify(newFilter);
  }

  // TODO
  async findAircraftTypeByTail(isAhmCalc = null) {
    const xRequestId = this.settingsService.getRandomUuid();

    if (this.flight.tailId) {
      await this.restApi
        .getAhmData(this.flight.tailId, this.flight.id, xRequestId)
        .then(
          (data) => {
            this.calculationByAhm = true;
            this.flight.isAhmCalc = isAhmCalc ?? true;
          },
          (err) => {
            this.calculationByAhm = false;
            this.flight.isAhmCalc = isAhmCalc ?? false;
          }
        );
      for (const tail of this.references.tails) {
        if (tail.id === this.flight.tailId) {
          this.flight.aircraftTypeId = tail.aircraftTypeId;
          this.flight.aircraftTypeIata = tail.aircraftTypeIata;
          break;
        }
      }
    } else {
      this.calculationByAhm = false;
      this.flight.isAhmCalc = false;
    }
  }

  onClearAircraft() {
    this.flight.tailId = null;
    this.flight.aircraftTypeId = null;
    this.findAircraftTypeByTail();
  }

  changeAhmCalc(value) {

    this.flight.isAhmCalc = value;
  }

  updateDowDoiAfterCrewChange($event) {

    if ($event) {
      this.calculation.crew.schema.cabin = $event.cabin;
      this.calculation.crew.schema.cockpit = $event.cockpit;
      //???
      // this.calculation.crew.schema.extra = $event.extra;
      // this.calculationCrewComposition = <DowChangesCrew>$event;
      this.deltaDowDoi.standard.dow = $event.dow;
      this.deltaDowDoi.standard.doi = $event.doi;
      // TODO Проверить как отрабаотывает при изменении воды
      if (this.enterFactDowDoiAutomatically) {
        this.calculation.dow = this.deltaDowDoi.calculated.dow;
        this.calculation.doi = this.deltaDowDoi.calculated.doi;
      }
    }
  }

  changeUldType($event, target, type?) {

    // Не даем переставить, если в новом контейнере вес превышен
    const uldInfo = this.uldTypes.find((uld) => uld.name === $event.name);
    this.selectedBays = null;
    if (
      uldInfo &&
      uldInfo.maxWeight < target.weight &&
      $event.name.toUpperCase() !== 'BULK'
    ) {
      this.error.errorBay =
        this.globalI18n.getMessage(ModuleName.WeightBalance, 'overloadUld') +
        ' ' +
        $event.name +
        '!';
      target.uldType = type;
      this.viewParametrs.reloadUldTypeSelect = true;
      setTimeout(() => {
        this.viewParametrs.reloadUldTypeSelect = false;
      }, 100);
      setTimeout(() => {
        this.error.errorBay = '';
      }, 3000);
    } else {
      target.uldWeight = $event?.weight || 0;
      target.uldVolume = $event?.maxVolume || 0;
      target.owner =
        target.owner || this.flight.airlineIata || this.flight.airlineIcao;
      // Сбросим багажник, если в него нельзя ставить такой контейнер
      const newLocation = this.locations[$event.name].find(
        (item) => item.name === target.name
      );
      if (!newLocation) {
        this.removeNotocItem(target);
        target.name = null;
      }
      target.uldType = $event.name;
      if ($event.name.toUpperCase() === 'BULK') {
        target.owner = '';
        target.uldNum = '';
      }
    }
  }

  onDigitInput(event) {
    const element = event.nextElementSibling;
    if (element == null) return;
    else element.focus();
  }

  getTotalPassenger() {
    return this.calculation.passengers.cabinArea.reduce(
      (acc, element) => (acc += element.passengers.adult),
      0
    );
  }

  placesDeclared() {
    return this.flight.loading.business + this.flight.loading.economy;
  }

  getDocumentValue(edno, field): string | Date {
    for (const edition of this.documentEditions) {
      if (edition.edno === edno) {
        if (field === 'preparedBy') {
          return edition.preparedBy;
        } else if (field === 'preparedAt') {
          return edition.preparedAt;
        }
      }
    }
    return '';
  }

  displayError(err) {
    if (err.type) {
      this.error.errorType = err.type;
      if (typeof err.message === 'object') {
        if (this.settingsService.user.getLanguage()  == 'EN') {
          this.error.errorMessage = err.message[0];
        } else {
          this.error.errorMessage = err.message[1];
        }
      } else {
        this.error.errorMessage = err.message;
      }
    } else {
      this.error.errorMessage = err;
    }
  }

  /**
   * Разбивает сообщение на строки
   *
   * @param {text} текст сообщения
   * @param {textarea} элемент поля ввода
   */
  splitIntoLines(text: string, textarea?) {
    let coursorPosition;
    if (textarea) {
      coursorPosition = textarea.selectionStart;
    }
    let contentArray = [];
    if (text && text !== '') {
      contentArray = text.toUpperCase().split(/\n|\r\n/);
    }
    const maxLength = 30;
    const newContent = [];
    this.verifiedMessage = [];
    contentArray.forEach((element) => {
      if (element.length > maxLength) {
        if (element[maxLength] !== ' ') {
          let line = element.slice(0, maxLength);
          let lineEndIndex = line.lastIndexOf(' ') + 1;
          if (lineEndIndex == 0) {
            newContent.push(element);
            this.verifiedMessage.push(
              [
                true,
                element,
                this.globalI18n.getMessage(ModuleName.ComMan, 'textLineLong')
              ]
            );
          } else {
            while (element.length > maxLength) {
              const lineSlice = line.slice(0, lineEndIndex - 1);
              newContent.push(lineSlice);
              this.verifiedMessage.push([false, lineSlice]);
              element = element.slice(lineEndIndex);
              line = element.slice(0, maxLength);
              lineEndIndex = line.lastIndexOf(' ') + 1;
            }
            newContent.push(element);
            this.verifiedMessage.push([false, element]);
          }
        } else {
          newContent.push(element.slice(0, maxLength));
          this.verifiedMessage.push([false, element.slice(0, maxLength)]);
          newContent.push(element.slice(maxLength));
          this.verifiedMessage.push([false, element.slice(maxLength)]);
        }
      } else {
        newContent.push(element);
        this.verifiedMessage.push([false, element]);
      }
    });
    if (textarea) {
      textarea.value = newContent.join('\r\n');
      textarea.setSelectionRange(coursorPosition, coursorPosition);
    }
    return newContent.join('\r\n');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.generateBaysMap();
    const dataWell = this.ahmData.centreOfGravityChart[0];
    this.createGravity(this.dataWell);
    // if (this.gravityCentreBlock) {
    //   const width = this.gravityCentreBlock.nativeElement.clientWidth;
    //   this.context = createCanvas(width, width, this.gravityCentreBlock.nativeElement);
    //   this.incorrectCalculation = drawGravity(width, width, dataWell, this.chartData, this.ahmData, this.context, this.calculation.fuel.taxi, new WeightLimit(this.calculation.mzfw, this.calculation.mtow, this.calculation.mlw, this.ahmData.rampTaxiWeight));
    // }

    // if (this.gravityCentreBlockBig) {
    //   const height = document.documentElement.clientHeight - 100;
    //   this.contextBig = createCanvas(height, height, this.gravityCentreBlockBig.nativeElement);
    //   this.incorrectCalculation = drawGravity(height, height, dataWell, this.chartData, this.ahmData, this.contextBig, this.calculation.fuel.taxi, new WeightLimit(this.calculation.mzfw, this.calculation.mtow, this.calculation.mlw, this.ahmData.rampTaxiWeight));
    // }
    this.checkCalculationCorrect();
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеная пользователем
   * @param {ReferanceTail} item Элемент для поиска
   */
  customSelectSearchTail(term: string, item: ReferanceTail) {
    // Преобразуется все в верхний регистр
    // Из строк убираются все символы форматирования бортового номера
    // Производится поиск на вхождение по упрощенным строкам
    return (
      item.tail
        .toUpperCase()
        .replace(/[-=_+\\\/\s]/g, '')
        .indexOf(term.toUpperCase().replace(/[-=_+\\\/\s]/g, '')) !== -1
    );
  }

  // Функция сортировки массива Авиакомпаний по коду IATA для удобного
  // просмотра и поиска по выпадающему списку
  get airlinesSortIata(): ReferanceAirline[] {
    return this.references.airlines.sort((a, b) =>
      a.iata.localeCompare(b.iata)
    );
  }

  // Функция сортировки массива Авиакомпаний по коду ICAO для удобного
  // просмотра и поиска по выпадающему списку
  get airlinesSortIcao(): ReferanceAirline[] {
    return this.references.airlines.sort((a, b) =>
      a.icao.localeCompare(b.icao)
    );
  }

  get airportsSortIata(): ReferanceAirport[] {
    return this.references.airports.sort((a, b) =>
      a.iata.localeCompare(b.iata)
    );
  }

  /**
   * Функция поиска в выпадающим списке по нескольким параметрам
   * @param {string} term Строка для поиска введеня пользователем
   * @param {ReferanceAirport} item Элемент для поиска
   */
  customSelectSearchAirline(term: string, item) {
    term = term.toLowerCase();
    return term.length < 3
      ? (item.iata && item.iata.toLowerCase().indexOf(term) > -1) ||
          (item.code &&
            item.code[1] &&
            item.code[1].toLowerCase().indexOf(term) > -1)
      : term.length < 4
      ? item.icao && item.icao.toLowerCase().indexOf(term) > -1
      : (item.name &&
          item.name[0] &&
          item.name[0].toLowerCase().indexOf(term) > -1) ||
        (item.name &&
          item.name[1] &&
          item.name[1].toLowerCase().indexOf(term) > -1);
  }

  checkCalculationCorrect() {
    if (
      this.chartData &&
      this.chartData.length > 0 &&
      !this.incorrectCalculation
    ) {
      this.calculationCorrect = true;
    } else {
      this.calculationCorrect = false;
    }
  }

  unallocatedSeats(): number {
    //???
    return (
      this.placesDeclared() /*+ this.calculation.crew.additional.inCabin*/ -
      this.getTotalPassenger()
    );
  }

  /**
   * Функция получения зоны рассадки
   * @param {Array} cabinArea массив с секциями
   * @param {string} zone зона (AFT или FWD)
   */
  getZoneSeating(cabinArea, zone: string) {
    let result: Array<CabinArea> = [];
    switch (zone) {
      case 'FWD':
        result = cabinArea.filter((el) => el.index < 0);
        break;
      case 'AFT':
        result = cabinArea.filter((el) => el.index > 0);
        break;
    }
    return result;
  }

  /* TODO: реализовать отображение цифр с пробелами */
  formatNumber(number) {
    return new Intl.NumberFormat('ru-RU').format(number);
  }

  strToNumber(str) {
    return +str.replaceAll(' ', '');
  }

  changeEnterFactDowDoiAutomatically() {
    this.enterFactDowDoiAutomatically = !this.enterFactDowDoiAutomatically;
    if (this.enterFactDowDoiAutomatically) {
      this.calculation.dow = this.deltaDowDoi.calculated.dow;
      this.calculation.doi = this.deltaDowDoi.calculated.doi;
    }
  }
  /* END TODO */

  changeAirport(route, item) {
    route.airportIata = item.iata;
    route.airportIcao = item.icao;
    route.airportCode = item.code;
  }

  // Рисуем карту
  async generateBaysMap() {
    // получаем справочник типов ULD для отрисовки
    let uldBaseSizes;
    const xRequestId = this.settingsService.getRandomUuid();
    await this.restApi.getReference('uld_base_sizes', xRequestId).then(
      (data) => {
        uldBaseSizes = data;
      },
      (err) => {
        this.displayError(err);
      }
    );

    const bayMap = document.getElementById('bayMap');
    if (bayMap) {
      const args = {
        bayMap,
        holdsAndCompartments: this.ahmData.holdsAndCompartments,
        uldTypes: this.uldTypes,
        uldBaseSizes,
        untils: this.ahmData.units,
      };
      this.trunkMap = bayMapFunctons.generateMap(args);
    }

    this.trunkMap.loading = false;
  }

  async dragEnd() {
    const bay = this.trunkMap.targetBay;
    const cargo = this.trunkMap.darggableCargo;
    if (bay) {
      await this.relocateCargo(bay, cargo);
    }
    this.trunkMap.filterType = null;
    this.trunkMap.darggableCargo = null;
    this.trunkMap.locationEvent = null;
    this.selectedBays = null;
  }

  async changeBay(targetBay, cargo) {
    if (targetBay?.name && this.checkLoadedInterceptions(targetBay)) {
      this.viewParametrs.reloadLocationsSelect = true;
      this.error.errorBay =
        targetBay.name +
        ' ' +
        this.globalI18n.getMessage(ModuleName.WeightBalance, 'bayBlocked');
      setTimeout(() => {
        this.viewParametrs.reloadLocationsSelect = false;
      }, 100);
      setTimeout(() => {
        this.error.errorBay = '';
      }, 3000);
      return;
    }
    if (targetBay) {
      this.relocateCargo(targetBay, [cargo]);
    } else {
      const uld = this.uldTypes.find((el) => el.name === cargo.uldType);
      if (cargo.uldType !== uld.name) {
        this.changeUldType(uld, cargo);
      }
      // При удалении позиции, необходимо удалить элемент из notoc
      this.removeNotocItem(cargo);
      // При удалении позиции, необходимо удалить элемент из выделенных
      if (this.selectedBays) {
        this.selectedBays = this.selectedBays.filter(
          (item) => item.name !== cargo.name
        );
        if (this.selectedBays.length === 0) {
          this.selectedBays = null;
        }
      }

      cargo.uldNum = null;
      cargo.name = null;
    }
    this.autoSave();
  }

  async relocateCargo(targetBay, cargo) {
    if (cargo && cargo.length > 0) {
      // находим все грузы, которые сложены в этот багажник
      const bays = this.calculation.holdsAndCompartments.filter(
        (el) => el.name === targetBay.name
      );
      // если там что-то есть - объединяем, либо меняем местами, если перетаскивание по карте
      if (bays && bays.length > 0) {
        if (this.trunkMap.locationEvent === 'map') {
          this.confirmSwapCargo(cargo, targetBay.name);
        } else {
          const loadedWeight = bays.reduce((acc, el) => acc + el.weight, 0);
          if (
            loadedWeight + this.getCargoWeight(cargo) <=
            targetBay.maxWeight
          ) {
            this.confirmRelocateCargo(cargo, targetBay.name, bays[0]);
          } else {
            this.viewParametrs.reloadLocationsSelect = true;
            this.error.errorBay = 'Overload bay ' + targetBay.name + '!';
            setTimeout(() => {
              this.viewParametrs.reloadLocationsSelect = false;
            }, 100);
            setTimeout(() => {
              this.error.errorBay = '';
            }, 3000);
            return;
          }
        }
      } else {
        if (this.checkLimitBays(targetBay.name[0], cargo)) {
          this.viewParametrs.reloadLocationsSelect = true;
          this.error.errorBay = 'Combined load limits exceeded!';
          setTimeout(() => {
            this.viewParametrs.reloadLocationsSelect = false;
          }, 100);
          setTimeout(() => {
            this.error.errorBay = '';
          }, 3000);
          return;
        }
        // иначе просто кладем в этот багажник
        const uld = this.uldTypes.find((el) => el.name === cargo[0].uldType);
        cargo.forEach((el) => {
          const oldName = el.name;
          el.name = targetBay.name;
          this.changeUldType(uld, el);
          const impCode = this.references.special_load_codes.find(
            (imp) => imp.code === el.imp
          );
          if (impCode !== undefined) {
            this.changeBayInNotoc(el, oldName, impCode);
          }
          // this.changeImpCode(el, impCode);
        });
      }
      // this.refreshLocations(targetBay);
    }
  }

  // refreshLocations(bay) {
  //   for (const key in this.locations) {
  //     if (Object.prototype.hasOwnProperty.call(this.locations, key)) {
  //       this.locations[key] = this.locations[key].filter(item => {
  //         if (!this.checkLoadedInterceptions(bay)) {
  //           return item.name !== bay;
  //         }
  //       });
  //     }
  //   }
  // }

  /**
   * Функция перемещения груза в багажник с подтверждением
   * @param cargo объект с грузом
   * @param name строка - имя багажника
   * @param bay объект багажника
   */
  async confirmRelocateCargo(
    cargo: Array<HoldsAndCompartmentsCalc>,
    name: string,
    bay
  ) {
    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'combineLoads'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        cargo.forEach((el) => {
          const notoc = this.findNotocItem(el.name, el.imp);
          el.name = name;
          el.uldType = bay.uldType;
          el.uldNum = bay.uldNum;
          el.owner = bay.owner;
          el.uldWeight = 0;
          if (notoc) {
            notoc.pos = el.name;
          } else {
            const code = this.references.special_load_codes.find(
              (codeItem) => codeItem.code === el.imp
            );
            if (code !== undefined) {
              this.createNotocItem(el, code);
            }
          }
        });
      }
      this.autoSave();
    });
  }

  /**
   * Функция замены местами груза в багажниках с подтверждением
   * @param cargo объект с грузом
   * @param name строка - имя багажника
   */
  async confirmSwapCargo(cargo: Array<HoldsAndCompartmentsCalc>, name: string) {
    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'swapLoads'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        const cargoTarget = this.calculation.holdsAndCompartments.filter(
          (el) => el.name === name
        );
        const cargoName = cargo[0].name;
        // const cargoTargetName = cargoTarget[0].name;
        cargoTarget.forEach((el) => {
          const notoc = this.findNotocItem(el.name, el.imp);
          if (notoc) {
            notoc.pos = cargoName;
          }
          el.name = cargoName;
        });
        cargo.forEach((el) => {
          const notoc = this.findNotocItem(el.name, el.imp);
          el.name = name;
          if (notoc) {
            notoc.pos = name;
          }
        });
      }
    });
  }

  findNotocItem(
    bayName: string,
    codeImp: string
  ): NotocDangerous | NotocOther | null {
    const notocDangerous = this.calculation.notoc.dangerous.find(
      (item) => item.pos === bayName && item.impDrill === codeImp
    );
    const notocOther = this.calculation.notoc.other.find(
      (item) => item.pos === bayName && item.impCode === codeImp
    );
    if (notocDangerous) {
      return notocDangerous;
    } else if (notocOther) {
      return notocOther;
    }
    return null;
  }

  getMapItemInfo(name: string) {
    const bays = this.calculation.holdsAndCompartments.filter(
      (el) => el.name === name
    );
    if (bays && bays.length > 0) {
      const result = {
        allWeight: 0,
        destination: [],
        type: [],
        uldType: [],
        volume: 0,
        weight: 0,
        pieces: 0,
        slc: false,
      };
      bays.forEach((bay) => {
        result.allWeight += bay.weight + bay.uldWeight;
        result.volume += bay.volume;
        result.weight += bay.weight;
        result.pieces += bay.pieces;
        if (bay.imp && bay.imp !== '') {
          result.slc = true;
        }
        if (!result.destination.includes(bay.destination)) {
          result.destination.push(bay.destination);
        }
        if (!result.type.includes(bay.type[0])) {
          result.type.push(bay.type[0]);
        }
        if (!result.uldType.includes(bay.uldType)) {
          result.uldType.push(bay.uldType);
        }
      });
      result.destination = [result.destination.join(',')];
      result.type = [result.type.join(',')];
      result.uldType = [result.uldType.join(',')];

      return result;
    } else {
      return false;
    }
  }

  printMapItemInfo(name) {
    const bayIno = this.getMapItemInfo(name);
    if (bayIno) {
      return `${bayIno.uldType}<br>
            ${bayIno.destination}<br>
            ${bayIno.type[0]}
            ${bayIno.pieces !== 0 ? '<br>' + bayIno.pieces + ' PCS' : ''}<br>
            ${bayIno.allWeight}`;
    }
  }

  getBayVolume(name: string) {
    const bay = this.calculation.holdsAndCompartments.filter(
      (el) => el.name === name
    );
    if (bay && bay.length > 0) {
      return bay.reduce((acc, el) => acc + el.volume, 0);
    } else {
      return 0;
    }
  }

  dragCargoOnMap(name, location) {
    const cargo = this.calculation.holdsAndCompartments.filter(
      (el) => el.name === name
    );
    if (cargo && cargo.length > 0) {
      this.dragPlaceCargo(cargo);
    }
    this.trunkMap.locationEvent = location;
  }

  dragPlaceCargo(cargo) {
    clearTimeout(this.timeoutClearBay);
    if (this.selectedBays && this.selectedBays.includes(cargo[0])) {
      cargo = this.selectedBays;
    } else {
      this.selectedBays = cargo;
    }
    this.trunkMap.darggableCargo = cargo;
    this.trunkMap.filterType = cargo[0].uldType;
  }

  get locatedCargo() {
    return this.calculation.holdsAndCompartments.filter((el) => el.name) || [];
  }

  get unlocatedCargo() {
    return this.calculation.holdsAndCompartments.filter((el) => !el.name) || [];
  }

  focusBay(name: string) {
    const result = this.calculation.holdsAndCompartments.filter(
      (el) => el.name === name
    );
    this.selectedBays = result.length === 0 ? null : result;
    clearTimeout(this.timeoutClearBay);
  }

  blurBay() {
    this.timeoutClearBay = setTimeout(() => {
      this.selectedBays = null;
    }, 200);
  }

  selectAllCargo() {
    clearTimeout(this.timeoutClearBay);
    if (
      this.calculation.holdsAndCompartments?.length ===
      this.selectedBays?.length
    ) {
      this.selectedBays = null;
    } else {
      this.selectedBays = this.calculation.holdsAndCompartments;
    }
  }

  async deleteSelectCargo() {
    clearTimeout(this.timeoutClearBay);
    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'deleteSelected'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        this.calculation.holdsAndCompartments =
          this.calculation.holdsAndCompartments.filter(
            (el) => !this.selectedBays.includes(el)
          );
        this.selectedBays = null;
      }
    });
  }

  async uploadSelectCargo() {
    clearTimeout(this.timeoutClearBay);
    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'uploadSelected'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        this.selectedBays.forEach((el) => {
          this.removeNotocItem(el);
          el.name = '';
        });
        // this.calculation.notoc.dangerous = [];
        // this.calculation.notoc.other = [];
      }
      this.selectedBays = null;
    });
  }

  calcEconomPassengers(route, i) {
    route.economy[i] =
      route.adult[i] + route.female[i] + route.child[i] - route.business[i];
  }

  dataForLineBalanceGraph(): BalanceСhart | null {
    for (const char of this.chartData) {
      if (char.type === this.linearCenteringGraphicsType) {
        return char;
      }
    }
    return null;
  }

  /**
   * Функция отображения индексов на линейном графике центровки (внизу справо)
   */
  get linearCenteringFwd() {
    const chart = this.dataForLineBalanceGraph();
    if (chart === null) {
      return '';
    } else {
      return chart.fwd.toFixed(2);
    }
  }

  /**
   * Функция отображения индексов на линейном крафике центровки (внизу справо)
   */
  get linearCenteringAft() {
    const chart = this.dataForLineBalanceGraph();
    if (chart === null) {
      return '';
    } else {
      return chart.aft.toFixed(2);
    }
  }

  /**
   * Пример массивоа с расчетными индексами
   * "weight": 44879,
   * "index": 89.21,
   * "type": "takeoff",
   * "aft": 77.43,
   * "fwd": 174.31
   */
  get percentageBalancePosition(): number {
    const chart = this.dataForLineBalanceGraph();
    // Если нет расчетного значения, вернуть признак что не надо показывать
    // линейный график
    if (chart === null) {
      return -1;
    }
    // FWD (перед) в правой стороне оси, AFT (корма/зад) в левой стороне оси
    if (chart.index <= chart.fwd) {
      // Если текущий индекс меньше значения по передней оси, то
      // ВС завален вперед, вернем 0 что бы отобразить бегунок в линейном
      // отобржаении в крайнем положении слево
      return 0;
    } else if (chart.index >= chart.aft) {
      // Если текущий индекс больше значения по задней оси, то
      // ВС завален назад, вернем 100 что бы отобразить бегунок в линейном
      // отобржаении в крайнем положении справо
      return 100;
    } else {
      // Текущий индекс лежит в пределах передней и задней оси,
      // расчитаем процент его удаленности от передней оси
      return 100 - ((chart.aft - chart.index) / (chart.aft - chart.fwd)) * 100;
    }
  }

  uldTotal(uld: string, cargoWeight: number, uldWeight: number) {
    for (const item of this.uldTypes) {
      if (item.name === uld) {
        if (uldWeight) {
          return uldWeight + cargoWeight;
        } else {
          return item.weight + cargoWeight;
        }
      }
    }
    return cargoWeight;
  }

  uldNet(cargoWeight: number) {
    return cargoWeight;
  }

  uldTare(uld: string, uldWeight: number) {
    if (uldWeight) {
      return uldWeight;
    } else {
      for (const item of this.uldTypes) {
        if (item.name === uld) {
          return item.weight;
        }
      }
    }
  }

  uldMax(uld: string) {
    for (const item of this.uldTypes) {
      if (item.name === uld) {
        return item.maxWeight;
      }
    }
  }

  uldMaxVolume(uld: string): number {
    for (const item of this.uldTypes) {
      if (item.name === uld) {
        return item.maxVolume;
      }
    }
  }

  /**
   * Функция сбора массива всех возможных багажников
   * @param { Object } holdsAndCompartments объект с багажниками их anmData
   */
  getAllLocations(holdsAndCompartments) {
    const locations = [];

    holdsAndCompartments.fwd.forEach((el) => {
      if (el.index !== 0 && el.maxWeight !== 0) {
        locations.push(el);
      }
      if (el.bays && el.bays.length > 0) {
        el.bays.forEach((bay) => {
          locations.push(bay);
        });
      }
    });

    holdsAndCompartments.aft.forEach((el) => {
      if (el.index !== 0 && el.maxWeight !== 0) {
        locations.push(el);
      }
      if (el.bays && el.bays.length > 0) {
        el.bays.forEach((bay) => {
          locations.push(bay);
        });
      }
    });

    return locations;
  }

  checkCargoWeight(cargo: HoldsAndCompartmentsCalc, event) {
    const max = this.uldMax(cargo.uldType) - cargo.uldWeight;
    if (
      max > 0 &&
      event.value > max &&
      cargo.uldType.toUpperCase() !== 'BULK'
    ) {
      event.value = cargo.weight;
      this.error.errorBay = 'Overload ULD ' + cargo.uldType + '!';
    } else if (cargo.name) {
      const bay = this.locations[cargo.uldType].find(
        (el) => el.name === cargo.name
      );
      if (bay && +event.value + cargo.uldWeight > bay.maxWeight) {
        event.value = cargo.weight;
        this.error.errorBay = 'Overload bay ' + cargo.name + '!';
      } else {
        cargo.weight = +event.value;
        this.error.errorBay = '';
      }
    } else {
      cargo.weight = +event.value;
      this.error.errorBay = '';
    }
    setTimeout(() => {
      this.error.errorBay = '';
    }, 3000);
  }

  trunkMapClassList(mapItem) {
    const classList = [];
    const mapInfo = this.getMapItemInfo(mapItem.name);
    let weight = 0;
    let volume = 0;
    if (mapInfo) {
      weight = mapInfo.weight;
      volume = mapInfo.volume;
      classList.push('occupied');
      if (mapItem.maxWeight < weight) {
        classList.push('overload');
      }
    } else {
      // заблокировать если пересекающийся заполнен
      if (this.checkLoadedInterceptions(mapItem)) {
        classList.push('disabled');
      }
    }
    // заблокировать, если не подходит по типу (и при этом в нем ничего нет, с чем можно объединить)
    if (
      this.trunkMap.filterType &&
      !mapItem?.uldTypes.includes(this.trunkMap.filterType.toUpperCase())
    ) {
      if (!mapInfo || this.trunkMap.locationEvent === 'map') {
        classList.push('disabled');
        classList.push(mapItem.uldTypes);
      }
    }
    // проверка на превышение веса/объема
    if (this.trunkMap.darggableCargo) {
      if (!this.trunkMap.filterType) {
        classList.push('disabled');
      }
      // перетаскиваемый груз
      const draggable = this.trunkMap.darggableCargo;
      let allWeight = this.getCargoWeight(draggable);
      let allVolume = this.getCargoVolume(draggable);
      // если тащим из таблицы - то добавляем вес того, который тащим
      if (this.trunkMap.locationEvent !== 'map') {
        allWeight += weight || draggable[0].uldWeight;
        allVolume += volume;
      }
      // const allWeight = this.getCargoWeight(draggable) + (weight || draggable[0].uldWeight);
      // const allVolume = this.getCargoVolume(draggable) + volume;
      if (mapItem.maxWeight > 0 && mapItem.maxWeight < allWeight) {
        classList.push('disabled');
      } else if (mapItem.maxVolume > 0 && mapItem.maxVolume < allVolume) {
        classList.push('disabled');
      } else if (this.checkOverloadSection(mapItem, draggable, weight)) {
        classList.push('disabled');
      } else if (this.checkLimitBays(mapItem.name[0], draggable, weight)) {
        classList.push('disabled');
      } else {
        classList.push('allowed');
      }
    }
    // проверка на превышение веса/объема в багажнике в целом

    // проверка на превышение веса/объема в нескольких багажниках по лимитам
    return classList.join(' ');
  }

  /**
   * Функция ищет пересекающиеся с переданным багажники и возвращает true если какой-то из них загружен
   * @param mapItem объект багажника, для которого ищем пересечения
   */
  checkLoadedInterceptions(mapItem) {
    let disabled = false;

    const interceptions =
      this.ahmData.holdsAndCompartments.interceptions.filter(
        (el) => el.cmp1 === mapItem.name
      );

    interceptions.forEach((el) => {
      const interceptionInfo = this.getMapItemInfo(el.cmp2);
      if (interceptionInfo) {
        disabled = true;
      }
    });

    return disabled;
  }

  /**
   * Функция проверяет, не будет ли перегружена секция, если поместить туда груз
   * @param mapItem объект багажника, на который тащим
   * @param draggable объект багажника, который тащим
   * @param weight вес груза, который уже есть в багажнике
   */
  checkOverloadSection(mapItem, draggable, weight = 0): boolean {
    const section = this.findSectionByName(mapItem.name[0]);
    if (section && section.maxWeight !== 0) {
      // Есть ли уже груз, который тащим в этой секции
      const checkBay = section.bays.find(
        (item) => item.name === draggable[0].name
      );
      // Если нет - то добавить его вес, иначе взять вес в секции
      let total =
        this.getLoadCompartment(section.name) +
        (!checkBay ? this.getCargoWeight(draggable) : 0);
      if (this.trunkMap.locationEvent === 'map') {
        total = total - weight;
      }
      if (section && total > section.maxWeight) {
        return true;
      }
    }
    return false;
  }

  findSectionByName(name: string) {
    let section = this.ahmData.holdsAndCompartments.aft.find(
      (el) => el.name === name
    );
    if (!section) {
      section = this.ahmData.holdsAndCompartments.fwd.find(
        (el) => el.name === name
      );
    }
    return section || null;
  }

  checkExcessVolume(uldType: string, volume: number): boolean {
    const maxVolume = this.uldMaxVolume(uldType);
    if (maxVolume >= volume) {
      return true;
    }
    return false;
  }

  /**
   * Функция проверяет, не будет ли перегруза по лимитам, установленным для нескольких багажников
   * @param sectionName имя секции (1 символ)
   * @param draggable массив багажников, который тащим
   * @param weight вес груза, который уже есть в багажнике
   */
  checkLimitBays(sectionName: string, draggable, weight = 0) {
    const limits = this.ahmData.holdsAndCompartments.limits.filter((el) =>
      el.compartments.includes(sectionName)
    );
    let res = false;
    limits.forEach((limit) => {
      const sum = this.getSumWeightFromSections(limit.compartments);
      // Есть ли уже груз, который тащим в этой секции
      const checkBay = limit.compartments.some((name) => {
        const section = this.findSectionByName(name);
        const bay =
          section.bays.find((item) => item.name === draggable[0].name) || false;
        return bay;
      });
      // Если нет - то добавить его вес
      let total = sum + (!checkBay ? this.getCargoWeight(draggable) : 0);
      if (this.trunkMap.locationEvent === 'map') {
        total = total - weight;
      }
      if (limit.maxWeight < total) {
        res = true;
      }
    });
    return res;
  }

  /**
   * Функция считает общий вес по багажникам в нескольких секциях
   * @param sections массив названий секций (по 1 символу)
   */
  getSumWeightFromSections(sections: Array<string>) {
    const res = sections.reduce((acc: number, el: string) => {
      return acc + this.getLoadCompartment(el);
    }, 0);

    return res;
  }

  getCargoWeight(cargo): number {
    return cargo.reduce((acc, el) => acc + el.weight, 0);
  }

  getCargoVolume(cargo): number {
    return cargo.reduce((acc, el) => acc + el.volume, 0);
  }

  async reloadWeights() {
    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'reloadWeights'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        this.calculation.mzfw = this.ahmData.zeroFuelWeight;
        this.calculation.mtow = this.ahmData.takeOffWeight;
        this.calculation.mlw = this.ahmData.landingWeight;
        this.autoSave();
        this.calculate();
      }
    });
  }

  getPercentVolume(name: string, maxVolume: number): number {
    return maxVolume > 0 ? (this.getBayVolume(name) * 100) / maxVolume : 0;
  }

  getPercentVolumeByUld(name: string, uldType: string): number {
    const max = this.uldMaxVolume(uldType);
    return max > 0 ? (this.getBayVolume(name) * 100) / max : 0;
  }

  getPercentWeight(mapItem): number {
    const mapInfo = this.getMapItemInfo(mapItem.name);
    if (mapInfo) {
      let percent =
        mapItem.maxWeight > 0
          ? (mapInfo.allWeight * 100) / mapItem.maxWeight
          : 0;
      if (percent > 0 && percent < 20) {
        percent = 20;
      } else if (percent > 80 && percent < 100) {
        percent = 80;
      }
      return percent;
    } else {
      return 0;
    }
  }

  getPercentWeightSection(section): number {
    let percent =
      section.maxWeight > 0
        ? (this.getLoadCompartment(section.name) * 100) / section.maxWeight
        : 0;
    if (percent > 0 && percent < 20) {
      percent = 20;
    } else if (percent > 80 && percent < 100) {
      percent = 80;
    }
    return percent;
  }

  get deadloadTotalBruto() {
    let sum = 0;
    this.calculation.holdsAndCompartments.forEach((item) => {
      if (item.name && item.name.length > 0) {
        sum += item.allWeight;
      }
    });
    return sum;
  }

  get deadloadTotalNet() {
    let sum = 0;
    this.calculation.holdsAndCompartments.forEach((item) => {
      if (item.name && item.name.length > 0) {
        sum += item.weight;
      }
    });
    return sum;
  }

  get deadloadTotalTara() {
    let sum = 0;
    this.calculation.holdsAndCompartments.forEach((item) => {
      if (item.name && item.name.length > 0) {
        sum += item.uldWeight;
      }
    });
    return sum;
  }

  get deadloadTotalVolume() {
    let sum = 0;
    this.calculation.holdsAndCompartments.forEach((item) => {
      if (item.name && item.name.length > 0) {
        sum += item.volume;
      }
    });
    return sum.toFixed(2);
  }

  get deadloadLoaded() {
    const sum = this.allLoadWeight();
    return String(sum).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }

  get deadloadNotDistributed() {
    const sum =
      this.flight.loading.luggage +
      this.flight.loading.cargo +
      this.flight.loading.mail -
      this.allLoadWeight();
    return String(sum).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }

  get deadloadPayload() {
    const sum =
      this.flight.loading.luggage +
      this.flight.loading.cargo +
      this.flight.loading.mail;
    return String(sum).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }

  get deadloadTotalBaggage() {
    return this.deadloadTotal('B');
  }

  get deadloadTotalCargo() {
    return this.deadloadTotal('C');
  }

  get deadloadTotalMail() {
    return this.deadloadTotal('M');
  }

  get notDistributedBaggage() {
    return this.flight.loading.luggage - this.deadloadTotal('B');
  }

  get notDistributedCargo() {
    return this.flight.loading.cargo - this.deadloadTotal('C');
  }

  get notDistributedMail() {
    return this.flight.loading.mail - this.deadloadTotal('M');
  }

  get deadloadTotalOther() {
    let sum = 0;
    this.calculation.holdsAndCompartments.forEach((item) => {
      const char = item.type[0]?.toUpperCase();
      if (
        item.name &&
        item.name.length > 0 &&
        char !== 'C' &&
        char !== 'B' &&
        char !== 'M' &&
        !item.inDow
      ) {
        sum += item.weight;
      }
    });
    return sum;
  }

  deadloadTotal(type: string) {
    return this.calculation.holdsAndCompartments.reduce((acc, item) => {
      return item.name &&
        item.name.length > 0 &&
        item.type[0]?.toUpperCase() === type &&
        !item.inDow
        ? acc + item.weight
        : acc;
    }, 0);
  }

  separateNumberWithSpace(num: any) {
    if (num) {
      return String(num).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
    } else {
      return '';
    }
  }

  highlightSelectedBays(name) {
    let result = false;
    if (this.selectedBays && this.selectedBays.length > 0) {
      result = this.selectedBays.some((item) => item.name == name);
    }

    if (result) {
      return '0.2rem solid #2b99d2';
    } else {
      return 'none';
    }
  }

  percentageOfNumber(value, max) {
    const result = (value * 100) / max;
    return result > 100 ? 100 : result.toFixed(0);
  }

  focusNextFieldById(id: string) {
    const element = document
      .getElementById(id)
      .firstElementChild.firstElementChild.lastElementChild.getElementsByTagName(
        'input'
      );
    if (element.item(0)) {
      element.item(0).focus();
    }
  }

  get activeDesktopName() {
    if (this.workspaces && this.workspaces.length > 0) {
      const desk = this.workspaces.find(
        (item) => item.id === this.viewParametrs.activeDesktop
      );
      return desk !== undefined ? desk.name : 'All';
    }
  }

  getClDvByIata(iata) {
    return (
      this.impCodes.dangerous.filter((el) => el.iata === iata)[0]?.class || null
    );
  }

  // changeImpCode(i) {
  //
  //   this.calculation.notoc.dangerous[i].clDv = null;
  //   const impDrill = this.getClDvByIata(this.calculation.notoc.dangerous[i].impDrill);
  //   if (impDrill && impDrill.length === 1) {
  //     this.calculation.notoc.dangerous[i].clDv = impDrill[0]
  //   }
  // }

  changeImpCode(cargo: HoldsAndCompartmentsCalc, code = null) {
    if (cargo.name && code?.code) {
      const notocDangerous = this.calculation.notoc.dangerous.find(
        (item) => item.pos === cargo.name && item.impDrill === cargo.imp
      );
      const notocOther = this.calculation.notoc.other.find(
        (item) => item.pos === cargo.name && item.impCode === cargo.imp
      );
      if (notocDangerous && code?.category === 1) {
        cargo.imp = code?.code;
        notocDangerous.impDrill = cargo.imp;
      } else if (notocOther && code?.category === 2) {
        cargo.imp = code?.code;
        notocOther.impCode = cargo.imp;
      } else if (notocDangerous || notocOther) {
        this.removeNotocItem(cargo);
        cargo.imp = null;
        this.createNotocItem(cargo, code);
      } else {
        this.createNotocItem(cargo, code);
      }
    } else {
      this.removeNotocItem(cargo);
      cargo.imp = code?.code;
    }
  }

  changeBayInNotoc(cargo: HoldsAndCompartmentsCalc, oldName: string, code) {
    const notocDangerous = this.calculation.notoc.dangerous.find(
      (item) => item.pos === oldName && item.impDrill === code.code
    );
    const notocOther = this.calculation.notoc.other.find(
      (item) => item.pos === oldName && item.impCode === code.code
    );
    if (notocDangerous) {
      notocDangerous.pos = cargo.name;
    } else if (notocOther) {
      notocOther.pos = cargo.name;
    } else {
      this.createNotocItem(cargo, code);
    }
  }

  createNotocItem(cargo: HoldsAndCompartmentsCalc, code = null) {
    cargo.imp = code?.code;
    if (code?.category === 1) {
      const item = new NotocDangerous();
      item.impDrill = cargo.imp;
      item.pos = cargo.name;
      item.to = cargo.destination;
      item.uldCode = cargo.owner + (cargo.uldNum !== null ? cargo.uldNum : '');
      this.calculation.notoc.dangerous.push(item);
    } else if (code?.category === 2) {
      const item = new NotocOther();
      item.impCode = cargo.imp;
      item.pos = cargo.name;
      item.to = cargo.destination;
      item.uldCode = cargo.owner + (cargo.uldNum !== null ? cargo.uldNum : '');
      this.calculation.notoc.other.push(item);
    }
  }

  removeNotocItem(cargo: HoldsAndCompartmentsCalc) {
    this.calculation.notoc.dangerous = this.calculation.notoc.dangerous.filter(
      (item) => item.pos !== cargo.name || item.impDrill !== cargo.imp
    );
    this.calculation.notoc.other = this.calculation.notoc.other.filter(
      (item) => item.pos !== cargo.name || item.impCode !== cargo.imp
    );
  }

  updateNotocParam(
    cargo: HoldsAndCompartmentsCalc,
    param: string,
    value: string
  ) {
    const notoc = this.findNotocItem(cargo.name, cargo.imp);
    if (notoc) {
      notoc[param] = value;
    }
  }

  async deleteCargoItem(cargo: HoldsAndCompartmentsCalc, index: number) {

    this.error.errorMessage = this.globalI18n.getMessage(
      ModuleName.WeightBalance,
      'deleteItem'
    );
    this.error.errorType = 'warning';
    this.modalType = 'setAnswer';
    await this.waitAnswer().then(async (res) => {
      if (res) {
        if (this.selectedBays && this.selectedBays.length > 0) {
          this.selectedBays = this.selectedBays.filter(
            (item) =>
              item.name !== this.calculation.holdsAndCompartments[index].name
          );
        }
        this.calculation.holdsAndCompartments.splice(index, 1);
        this.removeNotocItem(cargo);
        cargo.imp = null;
        this.autoSave();
      }
    });
  }

  get rampOverload(): boolean {
    if (this.calculation.fuel.taxi && this.calculation.tow) {
      if (
        this.calculation.fuel.taxi + this.calculation.tow >
        this.ahmData.rampTaxiWeight
      ) {
        return true;
      }
    }
    return false;
  }

  get additionalCrewOnPassengerSeats(): number {
    return this.calculation.crew.additionalSeats.passengerSeats.length;
  }

  get selectedFligts(): Flight[] {
    return this.flights.filter((item) => this.markFlightList.indexOf(item.id) !== -1);
  }

  changeFlightSeletedState(state?: boolean) {
    if (state) {
      this.selectFlightsState = state;
    } else {
      this.selectFlightsState = !this.selectFlightsState;
    }

    if (this.selectFlightsState) {
      this.flight = new Flight();
    } else {
      this.setMarkFlights(false);
    }
  }

  /**
   * Обновляет список рейсов выбранных пользователем
   * @param id {number} Идентификатор рейса
   * @param state True выбран, false не выбран
   */
  markFlight(id: number, state: boolean) {
    if (state) {
      if (this.markFlightList.indexOf(id) === -1) {
        this.markFlightList.push(id);
      }
    } else {
      this.markFlightList = this.markFlightList.filter(item => item != id);
    }
  }

  /**
   * Проверяет, выбран ли рейс пользователем
   * @param id Идентификатор рейса
   * @returns True если выбарн, иначе false
   */
  getMarkFlightState(id: number): boolean {
    if (this.markFlightList.indexOf(id) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Обновит список рейсов выбранных пользователем, добавив или удалив все рейсы
   * из суточного плана
   * @param state True добавить все, false удалить все
   */
  setMarkFlights(state: boolean) {
    if (state) {
      this.selectFlightsState = true;
      this.flightList.forEach(item => this.markFlightList.push(item.id));
    } else {
      this.markFlightList.length = 0;
    }
  }

  changeLinearCenteringGraphicsType(type: string) {
    this.linearCenteringGraphicsType = type;
    // this.globalSettings.setLinearCenteringGraphicsType(type);
		this.settingsService.user.setLinearCenteringGraphicsType(type);
    if (type == 'takeoff') {
      this.linearCenteringGraphicsTypeName = 'TOW';
    } else if (type == 'landing') {
      this.linearCenteringGraphicsTypeName = 'LW';
    } else if (type == 'zerofuel') {
      this.linearCenteringGraphicsTypeName = 'ZFW';
    }
  }

  get linearCenteringGraphicsCurrentValue() {
    if (this.linearCenteringGraphicsType == 'takeoff') {
      return this.calculation.litow ? this.calculation.litow.toFixed(2) : '';
    } else if (this.linearCenteringGraphicsType == 'landing') {
      return this.calculation.lilaw ? this.calculation.lilaw.toFixed(2) : '';
    } else if (this.linearCenteringGraphicsType == 'zerofuel') {
      return this.calculation.lizfw ? this.calculation.lizfw.toFixed(2) : '';
    }
  }

	getPassengersProvisionalInZone(name: string) {
		let passengersInZone = null;
		if (this.flight.payload.zones && this.flight.payload.zones.length > 0) {
			this.flight.payload.zones.forEach(zone => {
				if (zone.name === name) {
					passengersInZone = zone.passengers
				}
			})
		}
		return passengersInZone;
	}

	get language() {
		return this.settingsService.language;
	}

	get homeAirport() {
		return this.settingsService.general.getHomeAirport();
	}

	get currentUserTimeFormat() {
		return this.settingsService.user.getTime();
	}
}
